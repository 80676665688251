export const C_Constant_set9=
[
    {
        "question": "What is the value of the following expression in C: 28 / 7?",
        "options": [
            "3",
            "4",
            "3.5",
            "4.0"
        ],
        "answer": "4"
    },
    {
        "question": "Which of the following is a valid way to represent a binary constant in C?",
        "options": [
            "0b1010",
            "0xC5",
            "27H",
            "0xFF"
        ],
        "answer": "0b1010"
    },
    {
        "question": "What is the value of the following expression in C: 'G' - 'C'?",
        "options": [
            "1",
            "-1",
            "G",
            "71"
        ],
        "answer": "5"
    },
    {
        "question": "Which escape sequence is used to represent a tab character in C?",
        "options": [
            "\\t",
            "\\n",
            "\\r",
            "\\b"
        ],
        "answer": "\\t"
    },
    {
        "question": "Which of the following is NOT a valid way to represent an octal constant in C?",
        "options": [
            "092",
            "037",
            "0xFF",
            "0o777"
        ],
        "answer": "0xFF"
    },
    {
        "question": "What is the value of the following expression in C: 7.5 * 2?",
        "options": [
            "15",
            "14",
            "15.0",
            "14.0"
        ],
        "answer": "15.0"
    },
    {
        "question": "Which header file is required to use the `EOF` constant in C?",
        "options": [
            "<stdio.h>",
            "<stdlib.h>",
            "<math.h>",
            "<limits.h>"
        ],
        "answer": "<stdio.h>"
    },
    {
        "question": "Which of the following is a valid character constant in C?",
        "options": [
            "'G'",
            "'\\n'",
            "'12'",
            "'\\t'"
        ],
        "answer": "'G'"
    },
    {
        "question": "What is the value of the following expression in C: sizeof(ptrdiff_t)?",
        "options": [
            "4",
            "8",
            "16",
            "Depends on the compiler"
        ],
        "answer": "Depends on the compiler"
    },
    {
        "question": "Which of the following is NOT a valid constant type in C programming?",
        "options": [
            "Integer constant",
            "Floating-point constant",
            "Character constant",
            "Dynamic constant"
        ],
        "answer": "Dynamic constant"
    }
]
