export const C_String_set8=[
  {
    "question": "How do you check if two strings are equal in C?",
    "options": [
      "str1 == str2",
      "strcmp(str1, str2) == 0",
      "strcompare(str1, str2) == 0",
      "compare(str1, str2) == 0"
    ],
    "answer": "strcmp(str1, str2) == 0"
  },
  {
    "question": "What does the `strcpy()` function do in C?",
    "options": [
      "Copies one string to another",
      "Concatenates two strings",
      "Copies a specific number of characters from one string to another",
      "Finds the first occurrence of a character in a string"
    ],
    "answer": "Copies one string to another"
  },
  {
    "question": "How do you concatenate two strings in C?",
    "options": [
      "str1 + str2",
      "strcat(str1, str2)",
      "str1 . str2",
      "strconcat(str1, str2)"
    ],
    "answer": "strcat(str1, str2)"
  },
  {
    "question": "What does the `strncpy()` function do in C?",
    "options": [
      "Copies one string to another",
      "Concatenates two strings",
      "Copies a specific number of characters from one string to another",
      "Finds the first occurrence of a character in a string"
    ],
    "answer": "Copies a specific number of characters from one string to another"
  },
  {
    "question": "How do you find the length of a string in C?",
    "options": [
      "strlength()",
      "strlen()",
      "length()",
      "strcount()"
    ],
    "answer": "strlen()"
  },
  {
    "question": "Which function is used to find the first occurrence of a character in a string in C?",
    "options": [
      "strchr()",
      "findchar()",
      "strfind()",
      "charfind()"
    ],
    "answer": "strchr()"
  },
  {
    "question": "What is the null character in C?",
    "options": [
      "'\\n'",
      "'\\0'",
      "'\\t'",
      "'\\b'"
    ],
    "answer": "'\\0'"
  },
  {
    "question": "What does the `strcmp()` function return in C?",
    "options": [
      "0 if the strings are equal",
      "1 if the strings are equal",
      "-1 if the strings are equal",
      "None of the above"
    ],
    "answer": "0 if the strings are equal"
  },
  {
    "question": "How do you convert a string to uppercase in C?",
    "options": [
      "toupper()",
      "upper()",
      "strupper()",
      "strtoupper()"
    ],
    "answer": "toupper()"
  },
  {
    "question": "What is the correct way to compare two strings ignoring case in C?",
    "options": [
      "strcmp(str1, str2)",
      "strcasecmp(str1, str2)",
      "strcompare(str1, str2)",
      "compare(str1, str2)"
    ],
    "answer": "strcasecmp(str1, str2)"
  }
]
