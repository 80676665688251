// questionsData.js

export const Variable_set4_data = 
[
    {
        "question": "What is the primary purpose of the 'break' statement in C?",
        "options": ["To end the program execution", "To skip the current iteration of a loop", "To exit from a loop or switch statement", "To return a value from a function"],
        "answer": "To exit from a loop or switch statement"
    },
    {
        "question": "Which of the following data types is used to store a single character in C?",
        "options": ["int", "char", "float", "double"],
        "answer": "char"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5, y = 10;\n  printf(\"%d\", x++ * y--);\n  return 0;\n}\n```",
        "options": ["40", "50", "60", "70"],
        "answer": "50"
    },
    {
        "question": "Which of the following is not a valid arithmetic operator in C?",
        "options": ["+", "-", "*", "/", "%"],
        "answer": "%"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 10;\n  printf(\"%d\", ++x);\n  return 0;\n}\n```",
        "options": ["9", "10", "11", "Compiler error"],
        "answer": "11"
    },
    {
        "question": "What does the 'void' data type indicate in C function declarations?",
        "options": ["No return type", "Integer return type", "Character return type", "Boolean return type"],
        "answer": "No return type"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 10;\n  printf(\"%d\", x / 3);\n  return 0;\n}\n```",
        "options": ["3", "3.33", "3.0", "Compiler error"],
        "answer": "3"
    },
    {
        "question": "Which header file is required to use the 'scanf' function in C?",
        "options": ["<stdlib.h>", "<math.h>", "<time.h>", "<stdio.h>"],
        "answer": "<stdio.h>"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5;\n  printf(\"%d\", x += 2);\n  return 0;\n}\n```",
        "options": ["3", "5", "7", "Compiler error"],
        "answer": "7"
    },
    {
        "question": "What is the purpose of the 'do-while' loop in C?",
        "options": ["To execute a block of code as long as a condition is true", "To execute a block of code at least once, then repeat as long as a condition is true", "To execute a block of code a specific number of times", "To skip the current iteration of a loop"],
        "answer": "To execute a block of code at least once, then repeat as long as a condition is true"
    }
]

;
  