// questionsData.js

export const Expression_set3_data = [
      {
          "question": "What will be the result of the expression 2 * 3 + 4 * 5?",
          "options": ["26", "34", "25", "23"],
          "answer": "26"
      },
      {
          "question": "Which operator is used to perform logical AND in C?",
          "options": ["&&", "||", "!", "&"],
          "answer": "&&"
      },
      {
          "question": "What is the output of the expression 5 + 3 / 2?",
          "options": ["5.5", "4", "6", "3.5"],
          "answer": "6"
      },
      {
          "question": "Which operator is used to perform logical OR in C?",
          "options": ["&&", "||", "!", "&"],
          "answer": "||"
      },
      {
          "question": "What is the value of the expression (4 + 3) * (5 - 2)?",
          "options": ["21", "15", "12", "9"],
          "answer": "21"
      },
      {
          "question": "Which operator is used to perform logical NOT in C?",
          "options": ["&&", "||", "!", "&"],
          "answer": "!"
      },
      {
          "question": "What is the output of the expression 15 / 4?",
          "options": ["3", "3.75", "4", "3.5"],
          "answer": "3"
      },
      {
          "question": "Which operator is used to perform bitwise AND in C?",
          "options": ["&&", "&", "||", "|"],
          "answer": "&"
      },
      {
          "question": "What is the result of the expression 7 % 3?",
          "options": ["2", "3", "1", "0"],
          "answer": "1"
      },
      {
          "question": "Which operator is used to perform bitwise OR in C?",
          "options": ["&&", "&", "||", "|"],
          "answer": "|"
      }
  ];  