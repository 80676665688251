export const C_String_set6=[
  {
    "question": "What is the correct way to declare a string constant in C?",
    "options": [
      "string str = \"Hello\";",
      "char* str = \"Hello\";",
      "char str[] = \"Hello\";",
      "const char* str = \"Hello\";"
    ],
    "answer": "const char* str = \"Hello\";"
  },
  {
    "question": "How do you check if two strings are lexicographically less than or equal in C?",
    "options": [
      "str1 <= str2",
      "strcmp(str1, str2) <= 0",
      "strcompare(str1, str2) <= 0",
      "compare(str1, str2) <= 0"
    ],
    "answer": "strcmp(str1, str2) <= 0"
  },
  {
    "question": "Which function is used to convert a string to a long double in C?",
    "options": [
      "strtold()",
      "atol()",
      "strlongdouble()",
      "strtoldouble()"
    ],
    "answer": "strtold()"
  },
  {
    "question": "What is the correct way to compare two strings in C ignoring their case?",
    "options": [
      "stricmp(str1, str2)",
      "strcmp(str1, str2)",
      "strcasecmp(str1, str2)",
      "strcompare(str1, str2)"
    ],
    "answer": "strcasecmp(str1, str2)"
  },
  {
    "question": "How do you check if a string contains only alphabetic characters in C?",
    "options": [
      "isalpha()",
      "isalnum()",
      "isalphastr()",
      "strisalpha()"
    ],
    "answer": "strisalpha()"
  },
  {
    "question": "Which function is used to find the first occurrence of a character not in a string?",
    "options": [
      "strspn()",
      "strcspn()",
      "strfindnot()",
      "charfindnot()"
    ],
    "answer": "strcspn()"
  },
  {
    "question": "What does the `strxfrm()` function do in C?",
    "options": [
      "Converts a string to uppercase",
      "Converts a string to lowercase",
      "Transforms a string according to the current locale",
      "Copies a string with a maximum length specified"
    ],
    "answer": "Transforms a string according to the current locale"
  },
  {
    "question": "How do you check if a string contains only digits in C?",
    "options": [
      "isdigit()",
      "isnum()",
      "isnumeric()",
      "strisdigit()"
    ],
    "answer": "strisdigit()"
  },
  {
    "question": "Which function is used to convert a string to a float in C?",
    "options": [
      "atof()",
      "strtof()",
      "floatstr()",
      "strtofloat()"
    ],
    "answer": "strtof()"
  },
  {
    "question": "What is the correct way to concatenate two strings in C using `strcat()`?",
    "options": [
      "strcat(str1, str2)",
      "strcat(str2, str1)",
      "str1 + str2",
      "str2 + str1"
    ],
    "answer": "strcat(str1, str2)"
  }
]
