// questionsData.js

export const Expression_set4_data = [
      {
          "question": "What is the result of the expression 2 + 3 * 4?",
          "options": ["14", "20", "5", "10"],
          "answer": "14"
      },
      {
          "question": "Which operator is used to perform equality comparison in C?",
          "options": ["==", "!=", ">", "<"],
          "answer": "=="
      },
      {
          "question": "What will be the output of the expression 6 - 2 / 2?",
          "options": ["4", "5", "2", "3"],
          "answer": "5"
      },
      {
          "question": "Which operator is used to perform inequality comparison in C?",
          "options": ["==", "!=", ">", "<"],
          "answer": "!="
      },
      {
          "question": "What is the value of the expression (8 + 4) / (2 * 3)?",
          "options": ["4", "2", "6", "3"],
          "answer": "2"
      },
      {
          "question": "Which operator is used to perform greater than comparison in C?",
          "options": ["==", "!=", ">", "<"],
          "answer": ">"
      },
      {
          "question": "What is the result of the expression 10 / 4?",
          "options": ["2.5", "2", "3", "2.25"],
          "answer": "2"
      },
      {
          "question": "Which operator is used to perform less than comparison in C?",
          "options": ["==", "!=", ">", "<"],
          "answer": "<"
      },
      {
          "question": "What is the output of the expression 9 % 4?",
          "options": ["3", "2", "1", "0"],
          "answer": "1"
      },
      {
          "question": "Which operator is used to perform greater than or equal to comparison in C?",
          "options": [">=", "<=", "==", "!="],
          "answer": ">="
      }
  ];
  