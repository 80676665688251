export const C_If_Else_set2=
[
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 5;\n    if (x > 10)\n        printf(\"A\");\n    else if (x < 10)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "B"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 0;\n    if (x)\n        printf(\"Yes\");\n    else if (x == 0)\n        printf(\"Equal\");\n    else\n        printf(\"No\");\n    return 0;\n}\n",
    "options": [
      "Yes",
      "Equal",
      "No",
      "Compilation Error"
    ],
    "answer": "Equal"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 10;\n    if (x == 5)\n        printf(\"A\");\n    else if (x > 5)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "B"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 20;\n    if (x > 15)\n        printf(\"A\");\n    else if (x == 15)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "A"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = -5;\n    if (x > 0)\n        printf(\"A\");\n    else if (x < 0)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "B"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 10;\n    if (x > 10)\n        printf(\"A\");\n    else if (x == 10)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "B"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 5;\n    if (x != 5)\n        printf(\"A\");\n    else if (x == 5)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "B"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 0;\n    if (x)\n        printf(\"A\");\n    else if (x == 0)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "B"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 20;\n    if (x < 10)\n        printf(\"A\");\n    else if (x == 10)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "C"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 10;\n    if (x < 5)\n        printf(\"A\");\n    else if (x == 5)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "C"
  }
]
