export const GS_S4_data=[
    
  {
    "question": "நீரில் பிழியும் அனுக்கு என்ன விதியினால் தனிமரமாக மாறுகின்றது?",
    "options": ["நீரின் pH அளவை குறைக்கும்", "நீரின் அமில அளவை அதிகரிக்கும்", "நீரின் அமில அளவை குறைக்கும்", "நீரின் அமில அளவை அதிகரிக்கும்"],
    "answer": "நீரின் pH அளவை குறைக்கும்"
  },
  {
    "question": "முதுகுருணால் கொண்டுள்ள பிழிகள் எதைப் பயன்படுத்துகின்றன?",
    "options": ["வாயு", "ஆறு", "சமூகம்", "நீர்"],
    "answer": "ஆறு"
  },
  {
    "question": "எது மூலம் மூடிய வெளிப்படுத்தல் செய்கின்றது?",
    "options": ["அமில மதிப்பைக் குறைக்கும்", "உருவாக்கும்", "அமில மதிப்பைக் கூட்டும்", "அமில மதிப்பைக் களைக் குறைக்கும்"],
    "answer": "அமில மதிப்பைக் குறைக்கும்"
  },
  {
    "question": "தனியாக அமைக்கப்படும் கரிகள் எதைப் பயன்படுத்துகின்றன?",
    "options": ["கல்லால்", "உருவாகும்", "ஹெபர்லியம்", "தனிமரம்"],
    "answer": "தனிமரம்"
  },
  {
    "question": "மனிதர்கள் படிக்கும் பெருக்கத்திற்குப் பிறகு அவர்கள் என்ன வயது படுகிறார்கள்?",
    "options": ["2 மற்றும் 5 வயது", "6 மற்றும் 12 வயது", "10 மற்றும் 16 வயது", "18 மற்றும் 24 வயது"],
    "answer": "6 மற்றும் 12 வயது"
  },
  {
    "question": "பருத்தினத்தின் அடையாளம் எது?",
    "options": ["தனிமரமாகும்", "நீரில் பிழியும்", "கல்லால் உருவாகும்", "இரத்தக் குருணால் அடையாளமாகும்"],
    "answer": "இரத்தக் குருணால் அடையாளமாகும்"
  },
  {
    "question": "அறிக்கை வெளியிட்ட பருத்தினத்தின் பயன்கள் என்ன?",
    "options": ["உத்திகளை சேர்த்துக்கொள்ளுதல்", "அமைக்கும்", "அறிக்கை வெளியிடும்", "சரிந்தபோது உதவும்"],
    "answer": "சரிந்தபோது உதவும்"
  },
  {
    "question": "நீரில் பிழியும் அனுக்கு என்ன விதியினால் தனிமரமாக மாறுகின்றது?",
    "options": ["நீரின் pH அளவை குறைக்கும்", "நீரின் அமில அளவை அதிகரிக்கும்", "நீரின் அமில அளவை குறைக்கும்", "நீரின் அமில அளவை அதிகரிக்கும்"],
    "answer": "நீரின் pH அளவை குறைக்கும்"
  },
  {
    "question": "முதுகுருணால் கொண்டுள்ள பிழிகள் எதைப் பயன்படுத்துகின்றன?",
    "options": ["வாயு", "ஆறு", "சமூகம்", "நீர்"],
    "answer": "ஆறு"
  }
              
      
];
