// questionsData.js

export const Operators_set2_data = [
    {
        "question": "What is the arithmetic operator used for subtraction in C?",
        "options": ["+", "-", "*", "/"],
        "answer": "-"
    },
    {
        "question": "Which operator is used to find the quotient of a division operation in C?",
        "options": ["%", "/", "*", "+"],
        "answer": "/"
    },
    {
        "question": "What is the relational operator used to check if one value is greater than another in C?",
        "options": ["==", "!=", "<", ">"],
        "answer": ">"
    },
    {
        "question": "Which operator is used to compare two values for inequality in C?",
        "options": ["=", "==", "!=", "+="],
        "answer": "!="
    },
    {
        "question": "What is the logical OR operator in C?",
        "options": ["&&", "||", "!", "&"],
        "answer": "||"
    },
    {
        "question": "Which operator is used to perform bitwise OR in C?",
        "options": ["&", "|", "~", "^"],
        "answer": "|"
    },
    {
        "question": "What is the decrement operator in C?",
        "options": ["++", "--", "+=", "-="],
        "answer": "--"
    },
    {
        "question": "Which operator is used to perform logical NOT in C?",
        "options": ["&", "|", "!", "^"],
        "answer": "!"
    },
    {
        "question": "What is the ternary operator in C?",
        "options": ["?:", "?", ":", "||"],
        "answer": "?:"
    },
    {
        "question": "Which operator is used to access array elements in C?",
        "options": [".", "->", "*", "[]"],
        "answer": "[]"
    }
];