export const Java_Inheritance_data_1=[
    {
        "question": "What is inheritance in Java?",
        "options": ["A mechanism to access methods and fields of one class in another class", "A way to implement multiple interfaces in a class", "A process of creating a new class by extending an existing class", "A technique to override methods in a subclass"],
        "answer": "c) A process of creating a new class by extending an existing class"
    },
    {
        "question": "Which keyword is used to indicate inheritance in Java?",
        "options": ["extends", "implements", "inherits", "extendsOnly"],
        "answer": "a) extends"
    },
    {
        "question": "In Java, can a subclass access private members (fields and methods) of its superclass?",
        "options": ["Yes", "No"],
        "answer": "b) No"
    },
    {
        "question": "What is the term used to describe a class that inherits properties and behavior from another class?",
        "options": ["Parent class", "Derived class", "Super class", "Subclass"],
        "answer": "b) Derived class"
    },
    {
        "question": "Can a subclass override a private method defined in its superclass?",
        "options": ["Yes", "No"],
        "answer": "a) Yes"
    },
    {
        "question": "What is the default superclass for all classes in Java?",
        "options": ["java.lang.Object", "java.lang.Class", "java.util.Object", "java.util.Class"],
        "answer": "a) java.lang.Object"
    },
    {
        "question": "Which keyword is used to prevent a method from being overridden in Java?",
        "options": ["static", "final", "abstract", "private"],
        "answer": "b) final"
    },
    {
        "question": "In Java, can a subclass constructor call a constructor from its superclass?",
        "options": ["Yes", "No"],
        "answer": "a) Yes"
    },
    {
        "question": "What happens if a class extends another class that has a private constructor?",
        "options": ["Compilation error", "Runtime error", "No effect", "Constructor is automatically made protected"],
        "answer": "a) Compilation error"
    },
    {
        "question": "Which of the following is true about multiple inheritance in Java?",
        "options": ["Java supports multiple inheritance through classes", "Java supports multiple inheritance through interfaces", "Java does not support multiple inheritance", "Java supports multiple inheritance through abstract classes only"],
        "answer": "c) Java does not support multiple inheritance"
    }
];