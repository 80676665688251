export const C_Constant_set7=
[
    {
        "question": "What is the value of the following expression in C: 22 / 7?",
        "options": [
            "3.142",
            "3",
            "3.14",
            "3.0"
        ],
        "answer": "3"
    },
    {
        "question": "Which of the following is a valid way to represent a binary constant in C?",
        "options": [
            "0b1010",
            "0xC5",
            "27H",
            "0xFF"
        ],
        "answer": "0b1010"
    },
    {
        "question": "What is the value of the following expression in C: 'E' - 'B'?",
        "options": [
            "1",
            "-1",
            "E",
            "69"
        ],
        "answer": "4"
    },
    {
        "question": "Which escape sequence is used to represent a carriage return character in C?",
        "options": [
            "\\r",
            "\\n",
            "\\t",
            "\\b"
        ],
        "answer": "\\r"
    },
    {
        "question": "Which of the following is NOT a valid way to represent an octal constant in C?",
        "options": [
            "092",
            "037",
            "0xFF",
            "0o777"
        ],
        "answer": "0xFF"
    },
    {
        "question": "What is the value of the following expression in C: 6.5 * 2?",
        "options": [
            "13",
            "12",
            "13.0",
            "12.0"
        ],
        "answer": "13.0"
    },
    {
        "question": "Which header file is required to use the `NULL` constant in C?",
        "options": [
            "<stdio.h>",
            "<stdlib.h>",
            "<math.h>",
            "<stddef.h>"
        ],
        "answer": "<stddef.h>"
    },
    {
        "question": "Which of the following is a valid character constant in C?",
        "options": [
            "'E'",
            "'\\n'",
            "'12'",
            "'\\t'"
        ],
        "answer": "'E'"
    },
    {
        "question": "What is the value of the following expression in C: sizeof(ptrdiff_t)?",
        "options": [
            "4",
            "8",
            "16",
            "Depends on the compiler"
        ],
        "answer": "Depends on the compiler"
    },
    {
        "question": "Which of the following is NOT a valid constant type in C programming?",
        "options": [
            "Integer constant",
            "Floating-point constant",
            "Character constant",
            "Dynamic constant"
        ],
        "answer": "Dynamic constant"
    }
]
