export const C_Array_set2=[
    {
      "question": "What is the correct way to declare a character array named 'name' to store a string of up to 20 characters in C?",
      "options": [
        "char name[21];",
        "char name[20];",
        "char[] name = new char[20];",
        "name = char[20];"
      ],
      "answer": "char name[21];"
    },
    {
      "question": "What is the correct way to access the last element of an array named 'arr' of size n in C?",
      "options": [
        "arr[n-1]",
        "arr[n]",
        "arr[last]",
        "arr[-1]"
      ],
      "answer": "arr[n-1]"
    },
    {
      "question": "Which of the following is used to calculate the size of an array in C?",
      "options": [
        "sizeof(array) / sizeof(array[0])",
        "size(array)",
        "count(array)",
        "length(array)"
      ],
      "answer": "sizeof(array) / sizeof(array[0])"
    },
    {
      "question": "What does the following statement do in C: int numbers[5] = {1, 2, 3};",
      "options": [
        "Initializes the first 3 elements of the array 'numbers' with values 1, 2, and 3, and sets the rest to 0.",
        "Initializes the first 5 elements of the array 'numbers' with values 1, 2, 3, 0, and 0.",
        "Initializes the array 'numbers' with values 1, 2, and 3, and leaves the rest uninitialized.",
        "Raises a compilation error because not all elements are initialized."
      ],
      "answer": "Initializes the first 3 elements of the array 'numbers' with values 1, 2, and 3, and sets the rest to 0."
    },
    {
      "question": "Which of the following statements is true about passing arrays to functions in C?",
      "options": [
        "Arrays are always passed by reference to functions.",
        "Arrays are always passed by value to functions.",
        "Arrays are passed by reference if declared as pointers, otherwise, they are passed by value.",
        "Arrays cannot be passed to functions in C."
      ],
      "answer": "Arrays are always passed by reference to functions."
    },
    {
      "question": "What is the correct way to declare and initialize a two-dimensional array named 'matrix' with 2 rows and 3 columns in C?",
      "options": [
        "int matrix[2][3];",
        "int matrix[][] = {{1, 2, 3}, {4, 5, 6}};",
        "int matrix[3][2];",
        "int matrix[2, 3];"
      ],
      "answer": "int matrix[2][3];"
    },
    {
      "question": "Which of the following is true about multi-dimensional arrays in C?",
      "options": [
        "They can have different sizes for each dimension.",
        "All dimensions must have the same size.",
        "They can only have two dimensions.",
        "They cannot be accessed using indices."
      ],
      "answer": "All dimensions must have the same size."
    },
    {
      "question": "What does the following code snippet do in C? int arr[] = {1, 2, 3, 4, 5}; printf(\"%d\", arr[4]);",
      "options": [
        "Prints the size of the array.",
        "Prints the sum of all elements in the array.",
        "Prints the value stored at index 4 of the array.",
        "Prints the address of the array."
      ],
      "answer": "Prints the value stored at index 4 of the array."
    },
    {
      "question": "Which function is used to copy the contents of one array to another in C?",
      "options": [
        "memcpy()",
        "copy()",
        "move()",
        "strcpy()"
      ],
      "answer": "memcpy()"
    },
    {
      "question": "What is the output of the following code in C? int arr[5] = {1, 2, 3, 4, 5}; printf(\"%d\", arr[5]);",
      "options": [
        "1",
        "2",
        "5",
        "Garbage value"
      ],
      "answer": "Garbage value"
    }
  ]
  