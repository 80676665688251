export const C_Constant_set1=
[
    {
        "question": "Which of the following represents an octal constant in C?",
        "options": [
            "027",
            "0xFF",
            "0b1010",
            "037"
        ],
        "answer": "037"
    },
    {
        "question": "What escape sequence is used to represent a new line character in C?",
        "options": [
            "\\n",
            "\\t",
            "\\r",
            "\\b"
        ],
        "answer": "\\n"
    },
    {
        "question": "Which of the following is a valid way to represent a hexadecimal constant in C?",
        "options": [
            "0x2FG",
            "0xFAA",
            "27H",
            "0xFF"
        ],
        "answer": "0xFAA"
    },
    {
        "question": "What is the value of the following expression in C: 5 + 7.0?",
        "options": [
            "12",
            "12.0",
            "7",
            "5.7"
        ],
        "answer": "12.0"
    },
    {
        "question": "Which header file is required to use symbolic constants in C?",
        "options": [
            "<stdio.h>",
            "<stdlib.h>",
            "<math.h>",
            "<limits.h>"
        ],
        "answer": "<limits.h>"
    },
    {
        "question": "Which of the following is NOT a valid character constant in C?",
        "options": [
            "'A'",
            "'\\n'",
            "'12'",
            "'\\t'"
        ],
        "answer": "'12'"
    },
    {
        "question": "What is the value of the following expression in C: sizeof(float)?",
        "options": [
            "4",
            "8",
            "2",
            "Depends on the compiler"
        ],
        "answer": "4"
    },
    {
        "question": "Which of the following is a valid floating-point constant in C?",
        "options": [
            "6.02e23",
            "0xFF",
            "0.1f",
            "3"
        ],
        "answer": "0.1f"
    },
    {
        "question": "What is the value of the following expression in C: 10 / 3?",
        "options": [
            "3",
            "3.33",
            "3.0",
            "3.333"
        ],
        "answer": "3"
    },
    {
        "question": "Which of the following is NOT a valid constant type in C programming?",
        "options": [
            "Integer constant",
            "Floating-point constant",
            "Character constant",
            "Dynamic constant"
        ],
        "answer": "Dynamic constant"
    }
]
