// Data_Type_Route.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Mcqmaster from '../../Main/mcqmaster';
import Operators from '../../CProgramming/Operators/Operators';
import { Operators_set1_data } from '../../CProgramming/Operators/Data/Opeartors_data_1';
import { Operators_set2_data } from '../../CProgramming/Operators/Data/Opeartors_data_2';
import { Operators_set3_data } from '../../CProgramming/Operators/Data/Opeartors_data_3';
import { Operators_set4_data } from '../../CProgramming/Operators/Data/Opeartors_data_4';
import { Operators_set5_data } from '../../CProgramming/Operators/Data/Opeartors_data_5';
import { Operators_set6_data } from '../../CProgramming/Operators/Data/Opeartors_data_6';
import { Operators_set7_data } from '../../CProgramming/Operators/Data/Opeartors_data_7';
import { Operators_set8_data } from '../../CProgramming/Operators/Data/Opeartors_data_8';
import { Operators_set9_data } from '../../CProgramming/Operators/Data/Opeartors_data_9';
import { Operators_set10_data } from '../../CProgramming/Operators/Data/Opeartors_data_10';
const Operators_Route = () => {
  return (
    <Routes>
      <Route path="/" element={<Operators />} />
      <Route path="1" element={<Mcqmaster pageTitle="Operators MCQ Questions 1-10" questionsData={Operators_set1_data} previouslink="/c/operators/1" nextlink="/c/operators/2" />} />
      <Route path="2" element={<Mcqmaster pageTitle="Operators MCQ Questions 11-20" questionsData={Operators_set2_data} previouslink="/c/operators/1" nextlink="/c/operators/3" />} />
      <Route path="3" element={<Mcqmaster pageTitle="Operators MCQ Questions 21-30" questionsData={Operators_set3_data} previouslink="/c/operators/2" nextlink="/c/operators/4" />} />
      <Route path="4" element={<Mcqmaster pageTitle="Operators MCQ Questions 31-40" questionsData={Operators_set4_data} previouslink="/c/operators/3" nextlink="/c/operators/5" />} />

      <Route path="5" element={<Mcqmaster pageTitle="Operators MCQ Questions 41-50" questionsData={Operators_set5_data} previouslink="/c/operators/4" nextlink="/c/operators/6" />} />
      <Route path="6" element={<Mcqmaster pageTitle="Operators MCQ Questions 51-60" questionsData={Operators_set6_data} previouslink="/c/operators/5" nextlink="/c/operators/7" />} />
      <Route path="7" element={<Mcqmaster pageTitle="Operators MCQ Questions 61-70" questionsData={Operators_set7_data} previouslink="/c/operators/6" nextlink="/c/operators/8" />} />
      <Route path="8" element={<Mcqmaster pageTitle="Operators MCQ Questions 71-80" questionsData={Operators_set8_data} previouslink="/c/operators/7" nextlink="/c/operators/9" />} />

      <Route path="9" element={<Mcqmaster pageTitle="Operators MCQ Questions 81-90" questionsData={Operators_set9_data} previouslink="/c/operators/8" nextlink="/c/operators/10" />} />
      <Route path="10" element={<Mcqmaster pageTitle="Operators MCQ Questions 91-100" questionsData={Operators_set10_data} previouslink="/c/operators/9" nextlink="/c/operators/1" />} />
    </Routes>
  );
}

export default Operators_Route;
