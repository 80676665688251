export const C_String_set7=[
  {
    "question": "How do you find the number of occurrences of a character in a string in C?",
    "options": [
      "strcount()",
      "countchar()",
      "charcount()",
      "strchrcount()"
    ],
    "answer": "strcount()"
  },
  {
    "question": "Which function is used to check if a string contains only hexadecimal digits in C?",
    "options": [
      "isxdigit()",
      "ishex()",
      "ishexstr()",
      "strishex()"
    ],
    "answer": "strishex()"
  },
  {
    "question": "What is the correct way to copy a string in C?",
    "options": [
      "strcpy(str1, str2)",
      "copy(str1, str2)",
      "strcopy(str1, str2)",
      "str1 = str2"
    ],
    "answer": "strcpy(str1, str2)"
  },
  {
    "question": "How do you check if a string contains only whitespace characters in C?",
    "options": [
      "iswhite()",
      "isws()",
      "isspace()",
      "isblank()"
    ],
    "answer": "isspace()"
  },
  {
    "question": "Which function is used to find the last occurrence of a substring in a string?",
    "options": [
      "strfindlast()",
      "strstrlast()",
      "strrstr()",
      "findlaststr()"
    ],
    "answer": "strrstr()"
  },
  {
    "question": "What is the correct way to compare two strings in C ignoring their case?",
    "options": [
      "stricmp(str1, str2)",
      "strcmp(str1, str2)",
      "strcasecmp(str1, str2)",
      "strcompare(str1, str2)"
    ],
    "answer": "strcasecmp(str1, str2)"
  },
  {
    "question": "How do you extract a substring from a string in C?",
    "options": [
      "substr()",
      "strsub()",
      "strncpy()",
      "strcopy()"
    ],
    "answer": "strncpy()"
  },
  {
    "question": "What is the correct way to convert a string to uppercase in C?",
    "options": [
      "strupcase()",
      "toupper()"
    ],
    "answer": "toupper()"
  },
  {
    "question": "Which function is used to convert a string to lowercase in C?",
    "options": [
      "tolower()",
      "lower()",
      "strlower()",
      "strtolower()"
    ],
    "answer": "strlower()"
  },
  {
    "question": "What does the `strchr()` function return if the character is not found in the string?",
    "options": [
      "NULL",
      "0",
      "-1",
      "Empty string"
    ],
    "answer": "NULL"
  }
]
