// questionsData.js

export const Variable_set2_data = 
[
    {
        "question": "What is the data type of a variable 'x' declared as 'int x;' in C?",
        "options": ["Integer", "Character", "Float", "String"],
        "answer": "Integer"
    },
    {
        "question": "Which keyword is used to declare a constant variable in C?",
        "options": ["constant", "const", "fixed", "define"],
        "answer": "const"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  const int x = 5;\n  printf(\"%d\", x);\n  return 0;\n}\n```",
        "options": ["5", "Compiler error", "Garbage value", "Undefined"],
        "answer": "5"
    },
    {
        "question": "Which of the following is not a valid variable declaration in C?",
        "options": ["int x;", "int x = 10;", "x = 10;", "int x, y;"],
        "answer": "x = 10;"
    },
    {
        "question": "What is the purpose of the 'volatile' keyword in C?",
        "options": ["To declare a variable as volatile", "To declare a variable as constant", "To specify the scope of a variable", "To define a function"],
        "answer": "To declare a variable as volatile"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 10, y = 20;\n  printf(\"%d\", x + y);\n  return 0;\n}\n```",
        "options": ["10", "20", "30", "Compiler error"],
        "answer": "30"
    },
    {
        "question": "Which of the following is a valid variable declaration in C?",
        "options": ["float x = 3.14;", "x = 3.14;", "double x;", "int x();"],
        "answer": "float x = 3.14;"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5, y = 2;\n  printf(\"%d\", x / y);\n  return 0;\n}\n```",
        "options": ["2", "2.5", "3", "Compiler error"],
        "answer": "2"
    },
    {
        "question": "Which of the following is not a valid data type in C?",
        "options": ["int", "void", "boolean", "char"],
        "answer": "boolean"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 10;\n  printf(\"%d\", x++);\n  return 0;\n}\n```",
        "options": ["9", "10", "11", "Compiler error"],
        "answer": "10"
    }
]
;
  