import {React,useState} from 'react';
import Mainmcq from '../../Mainmcq';
import Cprogrammingcontent from '../../Cprogrammingcontent';
import '../../main.css'
import { categories } from '../../categoriesData';
import NavigationBar from '../../../Navbar/NavigationBar';
import { Control_Structures_set1_data } from './Control_Structures_data_1';
import { FaBars } from 'react-icons/fa'; // Import the FaBars icon

const pageTitle="TNPSC GROUP 4 SET 1 (1-10)"


const Control_Structures_set1 = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false); // State to track menu visibility

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible); // Toggle menu visibility
  };
  return (
    
    <div>
    <NavigationBar />
    <FaBars className="menu-icon" onClick={toggleMenu} />

    <div className="main-container">
      <div className={`sidebar-container ${isMenuVisible ? 'show-menu' : ''}`}>
        <Cprogrammingcontent categories={categories} />
      </div>
      <div className="mcq">
        {/* Pass the questions data to the mcq component */}
        <Mainmcq pageTitle={pageTitle} questionsData={Control_Structures_set1_data} />
      </div>
    </div>
    {/* Icon button for mobile */}
  </div>
   
  );
}

export default Control_Structures_set1;
