// questionsData.js

export const Control_Structures_set1_data = [
    {
        "question": "What is an expression in C?",
        "options": ["A combination of variables, constants, and operators that evaluates to a value", "A statement that declares a variable", "A loop construct", "A function call"],
        "answer": "A combination of variables, constants, and operators that evaluates to a value"
    },
    {
        "question": "What is the result of the following expression in C: 5 + 3 * 2?",
        "options": ["16", "11", "13", "10"],
        "answer": "11"
    },
    {
        "question": "Which of the following is a valid arithmetic expression in C?",
        "options": ["x = y + 2;", "x = y++;", "x = y *;", "x = y / 0;"],
        "answer": "x = y + 2;"
    },
    {
        "question": "What is the result of the following expression in C: (10 - 4) / 2?",
        "options": ["3", "2", "5", "1"],
        "answer": "3"
    },
    {
        "question": "Which operator is used to perform exponentiation in C?",
        "options": ["^", "**", "*", "/"],
        "answer": "**"
    },
    {
        "question": "What is the result of the following expression in C: 10 % 3?",
        "options": ["0", "1", "2", "3"],
        "answer": "1"
    },
    {
        "question": "Which of the following is a valid comparison expression in C?",
        "options": ["x >= y", "x = y", "x % y", "x + y"],
        "answer": "x >= y"
    },
    {
        "question": "What is the result of the following expression in C: 5 * (2 + 3)?",
        "options": ["25", "15", "10", "7"],
        "answer": "25"
    },
    {
        "question": "Which of the following is a valid logical expression in C?",
        "options": ["(x > 5) && (y < 10)", "(x + y)", "(x = y)", "(x != y)"],
        "answer": "(x > 5) && (y < 10)"
    },
    {
        "question": "What is the result of the following expression in C: 3 * 2 + 4 / 2?",
        "options": ["7", "5", "6", "9"],
        "answer": "7"
    }
    
    // Add more questions as needed
  ];
  