// Data_Type_Route.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Mcqmaster from '../../../Main/mcqmaster';
import Java_Inheritance from '../../../mcq/Programming/Java/Inheritance/Inheritance';
import { Java_Inheritance_data_1 } from '../../../mcq/Programming/Java/Inheritance/Data/Inheritance_data_1';
import { Java_Inheritance_data_2 } from '../../../mcq/Programming/Java/Inheritance/Data/Inheritance_data_2';
const Java_Inheritance_Route = () => {
  return (
    <Routes>
      <Route path="/" element={<Java_Inheritance />} />
   
      <Route path="1" element={<Mcqmaster pageTitle="Operators MCQ Questions 1-10" questionsData={Java_Inheritance_data_1} previouslink="/java/inheritance/1" nextlink="/java/inheritance/2" />} />
      <Route path="2" element={<Mcqmaster pageTitle="Operators MCQ Questions 11-20" questionsData={Java_Inheritance_data_2} previouslink="/java/inheritance/1" nextlink="/java/inheritance/3" />} />
      </Routes>
  );
}

export default Java_Inheritance_Route;
