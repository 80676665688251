// Data_Type_Route.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Mcqmaster from '../../Main/mcqmaster';
import { C_If_Else_set1 } from '../../CProgramming/If_Else/Data/C_If_Else_Set1';
import { C_If_Else_set2 } from '../../CProgramming/If_Else/Data/C_If_Else_Set2';
import { C_If_Else_set3 } from '../../CProgramming/If_Else/Data/C_If_Else_Set3';
import { C_If_Else_set4 } from '../../CProgramming/If_Else/Data/C_If_Else_Set4';
import { C_If_Else_set5 } from '../../CProgramming/If_Else/Data/C_If_Else_Set5';
import { C_If_Else_set6 } from '../../CProgramming/If_Else/Data/C_If_Else_Set6';
import { C_If_Else_set7 } from '../../CProgramming/If_Else/Data/C_If_Else_Set7';
import { C_If_Else_set8 } from '../../CProgramming/If_Else/Data/C_If_Else_Set8';
import { C_If_Else_set9 } from '../../CProgramming/If_Else/Data/C_If_Else_Set9';
import { C_If_Else_set10 } from '../../CProgramming/If_Else/Data/C_If_Else_Set10';
import C_Else_If from '../../CProgramming/If_Else/C_Else_If';




const C_Else_If_Route= () => {
  return (
    <Routes>
      <Route path="" element={<C_Else_If/>} />
      <Route path="1" element={<Mcqmaster pageTitle="else_if MCQ Questions 1-10" questionsData={C_If_Else_set1} previouslink="/c/else_if/1" nextlink="/c/else_if/2" />} />
      <Route path="2" element={<Mcqmaster pageTitle="else_if MCQ Questions 11-20" questionsData={C_If_Else_set2} previouslink="/c/else_if/1" nextlink="/c/else_if/3" />} />
      <Route path="3" element={<Mcqmaster pageTitle="else_if MCQ Questions 21-30" questionsData={C_If_Else_set3} previouslink="/c/else_if/2" nextlink="/c/else_if/4" />} />
      <Route path="4" element={<Mcqmaster pageTitle="else_if MCQ Questions 31-40" questionsData={C_If_Else_set4} previouslink="/c/else_if/3" nextlink="/c/else_if/5" />} />

      <Route path="5" element={<Mcqmaster pageTitle="else_if MCQ Questions 41-50" questionsData={C_If_Else_set5} previouslink="/c/else_if/4" nextlink="/c/else_if/6" />} />
      <Route path="6" element={<Mcqmaster pageTitle="else_if MCQ Questions 51-60" questionsData={C_If_Else_set6} previouslink="/c/else_if/5" nextlink="/c/else_if/7" />} />
      <Route path="7" element={<Mcqmaster pageTitle="else_if MCQ Questions 61-70" questionsData={C_If_Else_set7} previouslink="/c/else_if/6" nextlink="/c/else_if/8" />} />
      <Route path="8" element={<Mcqmaster pageTitle="else_if MCQ Questions 71-80" questionsData={C_If_Else_set8} previouslink="/c/else_if/7" nextlink="/c/else_if/9" />} />

      <Route path="9" element={<Mcqmaster pageTitle="else_if MCQ Questions 81-90" questionsData={C_If_Else_set9} previouslink="/c/else_if/8" nextlink="/c/else_if/10" />} />
      <Route path="10" element={<Mcqmaster pageTitle="else_if MCQ Questions 91-100" questionsData={C_If_Else_set10} previouslink="/c/else_if/9" nextlink="/c/else_if/1" />} />
  
    </Routes>
  );
}

export default C_Else_If_Route ;
