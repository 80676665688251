export const C_String_set1=[
  {
    "question": "Which of the following is a correct way to declare a string in C?",
    "options": [
      "string str;",
      "char* str;",
      "char str[];",
      "string* str;"
    ],
    "answer": "char str[];"
  },
  {
    "question": "What is the null character in C?",
    "options": [
      "'\\n'",
      "'\\0'",
      "'\\t'",
      "'\\b'"
    ],
    "answer": "'\\0'"
  },
  {
    "question": "Which function is used to calculate the length of a string in C?",
    "options": [
      "strlength()",
      "strlen()",
      "length()",
      "strcount()"
    ],
    "answer": "strlen()"
  },
  {
    "question": "How do you concatenate two strings in C?",
    "options": [
      "str1 + str2",
      "strcat(str1, str2)",
      "str1 . str2",
      "strconcat(str1, str2)"
    ],
    "answer": "strcat(str1, str2)"
  },
  {
    "question": "What does the `strcmp()` function return in C?",
    "options": [
      "0 if the strings are equal",
      "1 if the strings are equal",
      "-1 if the strings are equal",
      "None of the above"
    ],
    "answer": "0 if the strings are equal"
  },
  {
    "question": "Which function is used to copy one string to another in C?",
    "options": [
      "strcpy()",
      "copystr()",
      "strncpy()",
      "copy()"
    ],
    "answer": "strcpy()"
  },
  {
    "question": "What is the correct way to compare two strings ignoring case in C?",
    "options": [
      "strcmp(str1, str2)",
      "strcasecmp(str1, str2)",
      "strcompare(str1, str2)",
      "compare(str1, str2)"
    ],
    "answer": "strcasecmp(str1, str2)"
  },
  {
    "question": "How do you convert a string to uppercase in C?",
    "options": [
      "toupper()",
      "upper()",
      "strupper()",
      "strtoupper()"
    ],
    "answer": "strupper()"
  },
  {
    "question": "Which function is used to find the first occurrence of a character in a string in C?",
    "options": [
      "strchr()",
      "findchar()",
      "strfind()",
      "charfind()"
    ],
    "answer": "strchr()"
  },
  {
    "question": "Which of the following functions is used to tokenize a string in C?",
    "options": [
      "strsplit()",
      "strtok()",
      "splitstr()",
      "tokenstr()"
    ],
    "answer": "strtok()"
  }
]
