import React from 'react';
import NavigationBar from '../../Navbar/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'; // Import the desired icon
import Footer from '../../Footer/Footer';

const C_Else_If = () => {
    const subcategories = [
        { label: 'Set1', link: '/c/else_if/1' },
        { label: 'Set2', link: '/c/else_if/2' },
        { label: 'Set3', link: '/c/else_if/3' },
        { label: 'Set4', link: '/c/else_if/4' },
        { label: 'Set5', link: '/c/else_if/5' },
        { label: 'Set6', link: '/c/else_if/6' },
        { label: 'Set7', link: '/c/else_if/7' },
        { label: 'Set8', link: '/c/else_if/8' },
        { label: 'Set9', link: '/c/else_if/9' },
        { label: 'Set10', link:'/c/else_if/10' },
        // Add more subcategories as needed
      ];
    
      return (
        <div>
  <NavigationBar />

        <div className="container-fluid">
          <div className="row">
            <div className="col-9">
              <div className="row">
                {/* Map over the subcategories else_if to generate subcategory cards */}
                {subcategories.map((subcategory, index) => (
                  <div className="col-6 py-2" key={index}>
                    <FontAwesomeIcon icon={faFolderOpen} className="folder-icon" />
                    <a className="card-items" href={subcategory.link}>{subcategory.label}</a>
                  </div>
                ))}
              </div>
            </div>
            
          </div>
          
        </div>
            <div className="para">
                <p>
                In C programming, an <a href='/c/else_if'>else_if</a> is a data structure that allows you to store a collection of elements of the same type under a single name. Arrays provide a way to efficiently manage multiple values of the same data type by storing them in contiguous memory locations.
                </p>
            </div>
        <Footer/>
        </div>
      );
    };
    

export default C_Else_If;
