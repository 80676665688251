// Data_Type_Route.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Mcqmaster from '../../Main/mcqmaster';
import C_Array from '../../CProgramming/Array/C_Array';
import { C_Array_set1 } from '../../CProgramming/Array/Data/C_Array_set1';
import { C_Array_set2 } from '../../CProgramming/Array/Data/C_Array_set2';
import { C_Array_set3 } from '../../CProgramming/Array/Data/C_Array_set3';
import { C_Array_set4 } from '../../CProgramming/Array/Data/C_Array_set4';
import { C_Array_set5 } from '../../CProgramming/Array/Data/C_Array_set5';
import { C_Array_set6 } from '../../CProgramming/Array/Data/C_Array_set6';
import { C_Array_set7 } from '../../CProgramming/Array/Data/C_Array_set7';
import { C_Array_set8 } from '../../CProgramming/Array/Data/C_Array_set8';
import { C_Array_set9 } from '../../CProgramming/Array/Data/C_Array_set9';
import { C_Array_set10 } from '../../CProgramming/Array/Data/C_Array_set10';

const C_Array_Route = () => {
  return (
    <Routes>
      <Route path="" element={<C_Array />} />
      <Route path="1" element={<Mcqmaster pageTitle="Arrays MCQ Questions 1-10" questionsData={C_Array_set1} previouslink="/c/array/1" nextlink="/c/array/2" />} />
      <Route path="2" element={<Mcqmaster pageTitle="Arrays MCQ Questions 11-20" questionsData={C_Array_set2} previouslink="/c/array/1" nextlink="/c/array/3" />} />
      <Route path="3" element={<Mcqmaster pageTitle="Arrays MCQ Questions 21-30" questionsData={C_Array_set3} previouslink="/c/array/2" nextlink="/c/array/4" />} />
      <Route path="4" element={<Mcqmaster pageTitle="Arrays MCQ Questions 31-40" questionsData={C_Array_set4} previouslink="/c/array/3" nextlink="/c/array/5" />} />

      <Route path="5" element={<Mcqmaster pageTitle="Arrays MCQ Questions 41-50" questionsData={C_Array_set5} previouslink="/c/array/4" nextlink="/c/array/6" />} />
      <Route path="6" element={<Mcqmaster pageTitle="Arrays MCQ Questions 51-60" questionsData={C_Array_set6} previouslink="/c/array/5" nextlink="/c/array/7" />} />
      <Route path="7" element={<Mcqmaster pageTitle="Arrays MCQ Questions 61-70" questionsData={C_Array_set7} previouslink="/c/array/6" nextlink="/c/array/8" />} />
      <Route path="8" element={<Mcqmaster pageTitle="Arrays MCQ Questions 71-80" questionsData={C_Array_set8} previouslink="/c/array/7" nextlink="/c/array/9" />} />

      <Route path="9" element={<Mcqmaster pageTitle="Arrays MCQ Questions 81-90" questionsData={C_Array_set9} previouslink="/c/array/8" nextlink="/c/array/10" />} />
      <Route path="10" element={<Mcqmaster pageTitle="Arrays MCQ Questions 91-100" questionsData={C_Array_set10} previouslink="/c/array/9" nextlink="/c/array/1" />} />
    </Routes>
  );
}

export default C_Array_Route;
