import React from 'react';
import NavigationBar from '../../../Navbar/NavigationBar';
import './Java_sub_catogery.css'; // Make sure to import your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'; // Import the desired icon
import Footer from '../../../Footer/Footer';
const Java_sub_catogery = () => {
    const subcategories = [
        { label: 'Inheritance', link: '/java/inheritance' },
       // { label: 'Data Types', link: '/C/datatype' },
        //{ label: 'Operators', link: '/C/operators' },
        //{ label: 'Expressions', link: '/C/expressions' },
        // Add more subcategories as needed
      ];
    
      return (
        <div>
  <NavigationBar />
 

        <div className="container-fluid">
          <div className="row">
            <div className="col-9">
              <div className="row">
                {/* Map over the subcategories array to generate subcategory cards */}
                {subcategories.map((subcategory, index) => (
                  <div className="col-6 py-2" key={index}>
                    <FontAwesomeIcon icon={faFolderOpen} className="folder-icon" />
                    <a className="card-items" href={subcategory.link}>{subcategory.label}</a>
                  </div>
                ))}
              </div>
            </div>
            
          </div>
          
        </div>
            <div className="para">
                <p>C variables are containers used to store data in a C program. They must be declared before use, specifying the data type. Variables can hold integers, floating-point numbers, characters, and more. Initialization can be done at declaration. Proper understanding and management of variables are crucial for efficient programming in C.</p>

            </div>
        <Footer/>
        </div>
      );
    };
    

export default Java_sub_catogery;
