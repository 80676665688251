import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from '../Navbar/NavigationBar';
import Mainmcq from '../CProgramming/Mainmcq';
import Footer from '../Footer/Footer';

const Mcqmaster = ({ pageTitle, questionsData, previouslink, nextlink }) => {
  const [key, setKey] = useState(0); // State to force re-render of Mainmcq component

  useEffect(() => {
    // Update key to force re-render when questionsData changes
    setKey(prevKey => prevKey + 1);
  }, [questionsData]);

  return (
    <div>
      <NavigationBar />
      <div className="main-container">
        <div className="mcq">
          {/* Pass a unique key prop to force re-render of Mainmcq */}
          <Mainmcq key={key} pageTitle={pageTitle} questionsData={questionsData} />
        </div>
      </div>
      <div className="navigation-links">
        {/* Previous Link */}
        {previouslink && <Link to={previouslink}>Previous</Link>}
        {/* Next Link */}
        {nextlink && <Link to={nextlink}>Next</Link>}
      </div>
      <Footer />
    </div>
  );
}

export default Mcqmaster;
