export const DataType_set4_data = [
    
    {
        "question": "What are the entities whose values can be changed called?",
        "options": ["Constants", "Variables", "Modules", "Tokens"],
        "answer": "b"
    },
    {
        "question": "Which of the following is not a data type?",
        "options": ["Symbolic Data", "Alphanumeric Data", "Numeric Data", "Alphabetic Data"],
        "answer": "a"
    },
    {
        "question": "*@Ac# is a type of ________________ data.",
        "options": ["Symbolic", "Alphanumeric", "Alphabetic", "Numeric"],
        "answer": "b"
    },
    {
        "question": "Which of the following is not a basic data type in C language?",
        "options": ["float", "int", "real", "char"],
        "answer": "c"
    },
    {
        "question": "Which of the following is not a valid representation in bits?",
        "options": ["8-bit", "24-bit", "32-bit", "64-bit"],
        "answer": "b"
    },
    {
        "question": "BOOLEAN is a type of data type which basically gives a tautology or fallacy.",
        "options": ["True", "False"],
        "answer": "a"
    },
    {
        "question": "What does FORTRAN stands for?",
        "options": ["Formula Transfer", "Formula Transformation", "Formula Translation", "Format Transformation"],
        "answer": "c"
    },
    {
        "question": "The program written by the programmer in high level language is called _____________",
        "options": ["Object Program", "Source Program", "Assembled Program", "Compiled Program"],
        "answer": "b"
    },
    {
        "question": "A standardized language used for commercial applications.",
        "options": ["C", "Java", "COBOL", "FORTRAN"],
        "answer": "c"
    },
    {
        "question": "______________ define how the locations can be used.",
        "options": ["Data types", "Attributes", "Links", "Data Objects"],
        "answer": "b"
    }
    
];