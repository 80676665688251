import './Datatype.css'
import React from 'react';
import NavigationBar from '../../Navbar/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'; // Import the desired icon
import Footer from '../../Footer/Footer';

const Datatype = () => {
    const subcategories = [
        { label: 'Set1', link: '/c/datatype/1' },
        { label: 'Set2', link: '/c/datatype/2' },
        { label: 'Set3', link: '/c/datatype/3' },
          { label: 'Set4', link: '/c/datatype/4' },
       /* { label: 'Set5', link: '/c/datatype-5' },
        { label: 'Set6', link: '/c/datatype-6' },
        { label: 'Set7', link: '/c/datatype-7' },
        { label: 'Set8', link: '/c/datatype-8' },
        { label: 'Set9', link: '/c/datatype-9' },
        { label: 'Set10', link: '/c/datatype-10' },*/
        // Add more subcategories as needed
      ];
    
      return (
        <div>
  <NavigationBar />

        <div className="container-fluid">
          <div className="row">
            <div className="col-9">
              <div className="row">
                {/* Map over the subcategories array to generate subcategory cards */}
                {subcategories.map((subcategory, index) => (
                  <div className="col-6 py-2" key={index}>
                    <FontAwesomeIcon icon={faFolderOpen} className="folder-icon" />
                    <a className="card-items" href={subcategory.link}>{subcategory.label}</a>
                  </div>
                ))}
              </div>
            </div>
            
          </div>
          
        </div>
            <div className="para">
                <p>In C programming, data types are used to define the type of data that a variable can hold. Each data type has specific properties and uses, allowing programmers to manage memory efficiently and perform operations effectively.</p>

            </div>
        <Footer/>
        </div>
      );
    };
    

export default Datatype;
