export const C_If_Else_set10=[
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 5;\n    if (x == 5)\n        printf(\"A\");\n    else if (x == 6)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "A"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = -5;\n    if (x >= 0)\n        printf(\"Non-Negative\");\n    else\n        printf(\"Negative\");\n    return 0;\n}\n",
    "options": [
      "Non-Negative",
      "Negative",
      "Compilation Error",
      "Runtime Error"
    ],
    "answer": "Negative"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 10;\n    if (x > 10)\n        printf(\"A\");\n    else if (x < 10)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "C"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 0;\n    if (x)\n        printf(\"Non-zero\");\n    else\n        printf(\"Zero\");\n    return 0;\n}\n",
    "options": [
      "Non-zero",
      "Zero",
      "Non-zeroZero",
      "Compilation Error"
    ],
    "answer": "Zero"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 20;\n    if (x < 10)\n        printf(\"A\");\n    else if (x < 20)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "C"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 7;\n    if (x < 10)\n        printf(\"A\");\n    else if (x == 7)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "A"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 5;\n    if (x != 5)\n        printf(\"A\");\n    else if (x != 6)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "B"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = -10;\n    if (x > 0)\n        printf(\"Positive\");\n    else if (x < 0)\n        printf(\"Negative\");\n    else\n        printf(\"Zero\");\n    return 0;\n}\n",
    "options": [
      "Positive",
      "Negative",
      "Zero",
      "Compilation Error"
    ],
    "answer": "Negative"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 10;\n    if (x >= 5)\n        printf(\"Greater or Equal to 5\");\n    else if (x < 5)\n        printf(\"Less than 5\");\n    else\n        printf(\"Equal to 5\");\n    return 0;\n}\n",
    "options": [
      "Greater or Equal to 5",
      "Less than 5",
      "Equal to 5",
      "Compilation Error"
    ],
    "answer": "Greater or Equal to 5"
  }]
