import {React,useState} from 'react';
import NavigationBar from '../../../../Navbar/NavigationBar';
import { FaBars } from 'react-icons/fa'; // Import the FaBars icon
import Mainmcq from '../../../../CProgramming/Mainmcq';
import { GS_S6_data } from './GS_S6_data';
import Footer from '../../../../Footer/Footer';
import { Link } from 'react-router-dom';

const pageTitle="TNPSC GENERAL STUDIES SET 6 ( 51-60 )"


const GS_S6 = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false); // State to track menu visibility

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible); // Toggle menu visibility
  };
  return (
    
    <div>
    <NavigationBar />

    <div className="main-container">
     
      <div className="mcq">
        {/* Pass the questions data to the mcq component */}
        <Mainmcq pageTitle={pageTitle} questionsData={GS_S6_data} />
      </div>
    </div>
    <div className="navigation-links">
      <Link to={`/Tnpsc/IV/gs/s5`} >Previous</Link>
      <Link to={`/Tnpsc/IV/gs/s7`} >Next</Link>
    </div>
    <Footer/>
  </div>
   
  );
}

export default GS_S6;
