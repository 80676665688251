// questionsData.js

export const Variable_set1_data = 
[
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5;\n  printf(\"%d\", x * 2);\n  return 0;\n}\n```",
        "options": ["5", "10", "15", "Compiler error"],
        "answer": "10"
    },
    {
        "question": "What is a variable in C?",
        "options": ["A reserved word in C", "A value that cannot be changed", "A named location in memory", "An arithmetic operation"],
        "answer": "A named location in memory"
    },
    {
        "question": "Which of the following is a valid variable name in C?",
        "options": ["2variable", "_variable", "variable-name", "variable name"],
        "answer": "_variable"
    },
    {
        "question": "What is the syntax for declaring a variable in C?",
        "options": ["variableName = value;", "value = variableName;", "int variableName;", "variableName int;"],
        "answer": "int variableName;"
    },
    {
        "question": "What is the scope of a variable in C?",
        "options": ["The memory size allocated to the variable", "The range of statements in which the variable can be accessed", "The data type of the variable", "The value stored in the variable"],
        "answer": "The range of statements in which the variable can be accessed"
    },
    {
        "question": "Which of the following is not a valid variable type in C?",
        "options": ["int", "float", "string", "char"],
        "answer": "string"
    },
    {
        "question": "What is the purpose of initializing a variable in C?",
        "options": ["To allocate memory for the variable", "To specify the data type of the variable", "To assign an initial value to the variable", "To declare the variable as constant"],
        "answer": "To assign an initial value to the variable"
    },
    {
        "question": "What happens if a variable is declared without initialization in C?",
        "options": ["It automatically initializes to 0", "It contains garbage value", "It throws a compiler error", "It cannot be accessed"],
        "answer": "It contains garbage value"
    },
    {
        "question": "Which symbol is used for the assignment operator in C?",
        "options": ["=", "==", ":=", "->"],
        "answer": "="
    },
    {
        "question": "What is the maximum number of characters allowed for a variable name in C?",
        "options": ["10", "20", "32", "No limit"],
        "answer": "No limit"
    }
];
  