export const C_Constant_set6=
[
    {
        "question": "What is the value of the following expression in C: 18 % 5?",
        "options": [
            "3",
            "3.6",
            "0.6",
            "2"
        ],
        "answer": "3"
    },
    {
        "question": "Which of the following is a valid way to represent a hexadecimal constant in C?",
        "options": [
            "0xABG",
            "0xC5",
            "27H",
            "0b1010"
        ],
        "answer": "0xC5"
    },
    {
        "question": "What is the value of the following expression in C: 'D' - 'B'?",
        "options": [
            "1",
            "-1",
            "D",
            "68"
        ],
        "answer": "2"
    },
    {
        "question": "Which escape sequence is used to represent a tab character in C?",
        "options": [
            "\\t",
            "\\n",
            "\\r",
            "\\b"
        ],
        "answer": "\\t"
    },
    {
        "question": "Which of the following is NOT a valid way to represent a floating-point constant in C?",
        "options": [
            "3.14",
            "6.022e23",
            "0xFF",
            "0.1f"
        ],
        "answer": "0xFF"
    },
    {
        "question": "What is the value of the following expression in C: 15 / 4.0?",
        "options": [
            "3",
            "3.75",
            "3.0",
            "3.333"
        ],
        "answer": "3.75"
    },
    {
        "question": "Which header file is required to use the `RAND_MAX` constant in C?",
        "options": [
            "<stdio.h>",
            "<stdlib.h>",
            "<math.h>",
            "<limits.h>"
        ],
        "answer": "<stdlib.h>"
    },
    {
        "question": "Which of the following is a valid character constant in C?",
        "options": [
            "'D'",
            "'\\n'",
            "'12'",
            "'\\t'"
        ],
        "answer": "'D'"
    },
    {
        "question": "What is the value of the following expression in C: sizeof(size_t)?",
        "options": [
            "4",
            "8",
            "16",
            "Depends on the compiler"
        ],
        "answer": "Depends on the compiler"
    },
    {
        "question": "Which of the following is NOT a valid constant type in C programming?",
        "options": [
            "Integer constant",
            "Floating-point constant",
            "Character constant",
            "Dynamic constant"
        ],
        "answer": "Dynamic constant"
    }
]
