export const C_Constant_set8=
[
    {
        "question": "What is the value of the following expression in C: 25 % 6?",
        "options": [
            "4",
            "4.2",
            "0.4",
            "1"
        ],
        "answer": "1"
    },
    {
        "question": "Which of the following is a valid way to represent a hexadecimal constant in C?",
        "options": [
            "0x2FG",
            "0xC5",
            "27H",
            "0b1010"
        ],
        "answer": "0xC5"
    },
    {
        "question": "What is the value of the following expression in C: 'F' - 'C'?",
        "options": [
            "1",
            "-1",
            "F",
            "70"
        ],
        "answer": "3"
    },
    {
        "question": "Which escape sequence is used to represent a newline character in C?",
        "options": [
            "\\n",
            "\\t",
            "\\r",
            "\\b"
        ],
        "answer": "\\n"
    },
    {
        "question": "Which of the following is NOT a valid way to represent a floating-point constant in C?",
        "options": [
            "3.14",
            "6.022e23",
            "0xFF",
            "0.1f"
        ],
        "answer": "0xFF"
    },
    {
        "question": "What is the value of the following expression in C: 18 / 4.0?",
        "options": [
            "4",
            "4.5",
            "4.0",
            "4.25"
        ],
        "answer": "4.5"
    },
    {
        "question": "Which header file is required to use the `EXIT_FAILURE` constant in C?",
        "options": [
            "<stdio.h>",
            "<stdlib.h>",
            "<math.h>",
            "<errno.h>"
        ],
        "answer": "<stdlib.h>"
    },
    {
        "question": "Which of the following is a valid character constant in C?",
        "options": [
            "'F'",
            "'\\n'",
            "'12'",
            "'\\t'"
        ],
        "answer": "'F'"
    },
    {
        "question": "What is the value of the following expression in C: sizeof(size_t)?",
        "options": [
            "4",
            "8",
            "16",
            "Depends on the compiler"
        ],
        "answer": "Depends on the compiler"
    },
    {
        "question": "Which of the following is NOT a valid constant type in C programming?",
        "options": [
            "Integer constant",
            "Floating-point constant",
            "Character constant",
            "Dynamic constant"
        ],
        "answer": "Dynamic constant"
    }
]
