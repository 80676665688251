export const C_If_Else_set1=
[
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 10;\n    if (x > 5)\n        printf(\"Yes\");\n    else\n        printf(\"No\");\n    return 0;\n}\n",
    "options": [
      "Yes",
      "No",
      "YesNo",
      "Compilation Error"
    ],
    "answer": "Yes"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 5;\n    if (x != 0)\n        printf(\"Positive\");\n    else\n        printf(\"Negative\");\n    return 0;\n}\n",
    "options": [
      "Positive",
      "Negative",
      "PositiveNegative",
      "Compilation Error"
    ],
    "answer": "Positive"
  },
  {
    "question": "What is the output of the following C code snippet?\n\n#include <stdio.h>\n\nint main() {\n    int x = 0;\n    if (x)\n        printf(\"Yes\");\n    else\n        printf(\"No\");\n    return 0;\n}\n",
    "options": [
      "Yes",
      "No",
      "YesNo",
      "Compilation Error"
    ],
    "answer": "No"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 0;\n    if (!x)\n        printf(\"Yes\");\n    else\n        printf(\"No\");\n    return 0;\n}\n",
    "options": [
      "Yes",
      "No",
      "YesNo",
      "Compilation Error"
    ],
    "answer": "Yes"
  },
  {
    "question": "What is the output of the following C code snippet?\n\n#include <stdio.h>\n\nint main() {\n    int x = 10;\n    if (x = 5)\n        printf(\"Equal\");\n    else\n        printf(\"Not Equal\");\n    return 0;\n}\n",
    "options": [
      "Equal",
      "Not Equal",
      "Compilation Error",
      "Runtime Error"
    ],
    "answer": "Compilation Error"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 10;\n    if (x == 10)\n        printf(\"Yes\");\n    else\n        printf(\"No\");\n    return 0;\n}\n",
    "options": [
      "Yes",
      "No",
      "YesNo",
      "Compilation Error"
    ],
    "answer": "Yes"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = -5;\n    if (x > 0)\n        printf(\"Positive\");\n    else if (x < 0)\n        printf(\"Negative\");\n    else\n        printf(\"Zero\");\n    return 0;\n}\n",
    "options": [
      "Positive",
      "Negative",
      "Zero",
      "Compilation Error"
    ],
    "answer": "Negative"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 20;\n    if (x > 15)\n        printf(\"Greater\");\n    else\n        printf(\"Smaller\");\n    return 0;\n}\n",
    "options": [
      "Greater",
      "Smaller",
      "GreaterSmaller",
      "Compilation Error"
    ],
    "answer": "Greater"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 5, y = 10;\n    if (x == y)\n        printf(\"Equal\");\n    else if (x < y)\n        printf(\"Less than\");\n    else\n        printf(\"Greater than\");\n    return 0;\n}\n",
    "options": [
      "Equal",
      "Less than",
      "Greater than",
      "Compilation Error"
    ],
    "answer": "Less than"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 0;\n    if (x)\n        printf(\"Yes\");\n    else if (x == 0)\n        printf(\"Equal\");\n    else\n        printf(\"No\");\n    return 0;\n}\n",
    "options": [
      "Yes",
      "Equal",
      "No",
      "Compilation Error"
    ],
    "answer": "Equal"
  }
]
