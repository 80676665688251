// Data_Type_Route.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Datatype from '../../CProgramming/DataType/Datatype';
import Mcqmaster from '../../Main/mcqmaster';
import { DataType_set1_data } from '../../CProgramming/DataType/Set1/DataType_Data_1';
import { DataType_set2_data } from '../../CProgramming/DataType/Set2/DataType_Data_2';
import { DataType_set3_data } from '../../CProgramming/DataType/Set3/DataType_Data_3';
import { DataType_set4_data } from '../../CProgramming/DataType/Set4/DataType_Data_4';

const Data_Type_Route = () => {
  return (
    <Routes>
      <Route path="" element={<Datatype />} />
      <Route path="1" element={<Mcqmaster pageTitle="Data Type MCQ Questions 1-10" questionsData={DataType_set1_data} previouslink="/c/datatype" nextlink="/c/datatype/2" />} />
      <Route path="2" element={<Mcqmaster pageTitle="Data Type MCQ Questions 11-20" questionsData={DataType_set2_data} previouslink="/c/datatype/1" nextlink="/c/datatype/3" />} />
      <Route path="3" element={<Mcqmaster pageTitle="Data Type MCQ Questions 21-30" questionsData={DataType_set3_data} previouslink="/c/datatype/2" nextlink="/c/datatype/4" />} />
      <Route path="4" element={<Mcqmaster pageTitle="Data Type MCQ Questions 31-40" questionsData={DataType_set4_data} previouslink="/c/datatype/3" nextlink="/c/datatype/1" />} />
    </Routes>
  );
}

export default Data_Type_Route;
