export const C_Array_set6=[
    {
      "question": "Which of the following is true about arrays in C?",
      "options": [
        "Arrays can have a variable size.",
        "Arrays can have elements of different data types.",
        "Arrays cannot be resized once declared.",
        "Arrays can be directly initialized with values using the 'new' keyword."
      ],
      "answer": "Arrays cannot be resized once declared."
    },
    {
      "question": "What is the correct way to declare an array 'scores' to store 10 floating-point numbers in C?",
      "options": [
        "float scores[10];",
        "scores = float[10];",
        "scores[] = float[10];",
        "float[] scores = new float[10];"
      ],
      "answer": "float scores[10];"
    },
    {
      "question": "What does the sizeof operator return for an array in C?",
      "options": [
        "The number of elements in the array.",
        "The size of each element in the array.",
        "The total size of the array in bytes.",
        "The memory address of the first element in the array."
      ],
      "answer": "The total size of the array in bytes."
    },
    {
      "question": "Which of the following statements correctly assigns values to all elements of an array named 'data' in C?",
      "options": [
        "data[] = {1, 2, 3, 4, 5};",
        "{1, 2, 3, 4, 5} = data;",
        "for (int i = 0; i < 5; i++) data[i] = i + 1;",
        "data = {1, 2, 3, 4, 5};"
      ],
      "answer": "for (int i = 0; i < 5; i++) data[i] = i + 1;"
    },
    {
      "question": "What is the output of the following code in C? int arr[5] = {1, 2, 3}; printf(\"%d\", arr[4]);",
      "options": [
        "1",
        "2",
        "5",
        "Garbage value"
      ],
      "answer": "Garbage value"
    },
    {
      "question": "Which function is used to compare two arrays in C?",
      "options": [
        "strcmp()",
        "compare()",
        "memcmp()",
        "equals()"
      ],
      "answer": "memcmp()"
    },
    {
      "question": "What is the result of the expression 'sizeof(arr)' if 'arr' is an array of 10 integers in C?",
      "options": [
        "10",
        "40",
        "4",
        "Error"
      ],
      "answer": "40"
    },
    {
      "question": "Which of the following is true about array indexing in C?",
      "options": [
        "Arrays can only be indexed using integers.",
        "Arrays can be indexed using floating-point numbers.",
        "Arrays can be indexed using both positive and negative integers.",
        "Arrays cannot be indexed using variables."
      ],
      "answer": "Arrays can only be indexed using integers."
    },
    {
      "question": "What is the correct way to declare a two-dimensional array named 'matrix' with 3 rows and 4 columns in C?",
      "options": [
        "int matrix[3][4];",
        "int matrix[4][3];",
        "int matrix[3, 4];",
        "int matrix[4, 3];"
      ],
      "answer": "int matrix[3][4];"
    },
    {
      "question": "Which of the following statements is true about passing arrays as function arguments in C?",
      "options": [
        "Arrays are always passed by value.",
        "Arrays are always passed by reference.",
        "Arrays can be passed by value or by reference.",
        "Arrays cannot be passed as function arguments."
      ],
      "answer": "Arrays are always passed by reference."
    }
  ]
  