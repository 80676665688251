import React, { useState } from 'react';
import Mainmcq from '../../Mainmcq';
import Cprogrammingcontent from '../../Cprogrammingcontent';
import '../../main.css';
import { categories } from '../../categoriesData';
import NavigationBar from '../../../Navbar/NavigationBar';
import { Functions_data } from './Functions_data_1';
import { FaBars } from 'react-icons/fa'; // Import the FaBars icon

const pageTitle = "Function MCQ Questions 1-10";

const Function_set1 = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false); // State to track menu visibility

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible); // Toggle menu visibility
  };

  return (
    <div>
      <NavigationBar />
      <FaBars className="menu-icon" onClick={toggleMenu} />

      <div className="main-container">
        <div className={`sidebar-container ${isMenuVisible ? 'show-menu' : ''}`}>
          <Cprogrammingcontent categories={categories} />
        </div>
        <div className="mcq">
          {/* Pass the questions data to the mcq component */}
          <Mainmcq pageTitle={pageTitle} questionsData={Functions_data} />
        </div>
      </div>
      {/* Icon button for mobile */}
    </div>
  );
}

export default Function_set1;
