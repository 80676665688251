// questionsData.js

export const Operators_set1_data = [
    {
        "question": "What is the arithmetic operator used for addition in C?",
        "options": ["+", "-", "*", "/"],
        "answer": "+"
    },
    {
        "question": "Which operator is used to find the remainder of a division operation in C?",
        "options": ["%", "/", "*", "+"],
        "answer": "%"
    },
    {
        "question": "What is the relational operator used to check if two values are equal in C?",
        "options": ["==", "!=", "<", ">"],
        "answer": "=="
    },
    {
        "question": "Which operator is used to assign a value to a variable in C?",
        "options": ["=", "==", "+=", "-="],
        "answer": "="
    },
    {
        "question": "What is the logical AND operator in C?",
        "options": ["&&", "||", "!", "&"],
        "answer": "&&"
    },
    {
        "question": "Which operator is used to access the address of a variable in C?",
        "options": ["*", "&", "!", "->"],
        "answer": "&"
    },
    {
        "question": "What is the increment operator in C?",
        "options": ["++", "--", "+=", "-="],
        "answer": "++"
    },
    {
        "question": "Which operator is used for bitwise AND in C?",
        "options": ["&", "|", "~", "^"],
        "answer": "&"
    },
    {
        "question": "What is the ternary operator in C?",
        "options": ["?:", "?", ":", "||"],
        "answer": "?:"
    },
    {
        "question": "Which operator is used to access structure members in C?",
        "options": [".", "->", "*", "&"],
        "answer": "->"
    }
    
    // Add more questions as needed
  ];
  