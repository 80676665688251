import React, { useState } from 'react';
import './CategorySetPage.css';

const Cprogrammingcontent = ({ categories }) => {
  const [expandedCategories, setExpandedCategories] = useState({});

  // Function to toggle the dropdown for a category
  const toggleDropdown = (index) => {
    setExpandedCategories(prevState => {
      const newState = { ...prevState };
  
      // Close all other dropdowns
      Object.keys(newState).forEach(key => {
        if (key !== index) {
          newState[key] = false;
        }
      });
  
      // Toggle the dropdown for the clicked category
      newState[index] = !prevState[index];
  
      return newState;
    });
  };
  
  return (
    <div className="sidebar">
      {categories.map((category, index) => (
        <div key={index} className="category">
          <div className="category-header" onClick={() => toggleDropdown(index)}>
            <a className="category-title">{category.title} </a>
            <span className={`dropdown-icon ${expandedCategories[index] ? 'expanded' : ''}`}>
              &#9660; {/* Downward pointing triangle */}
            </span>
          </div>
          <div className={`subcategory ${expandedCategories[index] ? 'expanded' : ''}`}>
            {category.subcategories.map((subcategory, subIndex) => (
              <a key={subIndex} href={subcategory.link}>{subcategory.name}</a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Cprogrammingcontent;
