export const C_Array_set1=[
    {
      "question": "What is an array in C?",
      "options": [
        "A collection of elements with the same data type",
        "A function that returns multiple values",
        "A loop construct",
        "A variable that can store multiple data types"
      ],
      "answer": "A collection of elements with the same data type"
    },
    {
      "question": "How do you declare a one-dimensional array named 'numbers' of 5 integers in C?",
      "options": [
        "int numbers[5];",
        "array numbers[5];",
        "int[] numbers = new int[5];",
        "numbers = int[5];"
      ],
      "answer": "int numbers[5];"
    },
    {
      "question": "What is the correct way to access the third element of an array named 'arr' in C?",
      "options": [
        "arr[2]",
        "arr(3)",
        "arr{3}",
        "arr.3"
      ],
      "answer": "arr[2]"
    },
    {
      "question": "Which of the following statements is true about arrays in C?",
      "options": [
        "Arrays can only store elements of the same data type.",
        "Arrays can store elements of different data types.",
        "Arrays can store elements of different data types, but they need explicit type casting.",
        "Arrays can store elements of different data types, but only if they are declared as pointers."
      ],
      "answer": "Arrays can only store elements of the same data type."
    },
    {
      "question": "What is the correct way to initialize all elements of an array named 'arr' with zeros in C?",
      "options": [
        "memset(arr, 0, sizeof(arr));",
        "arr = {0};",
        "for (int i = 0; i < sizeof(arr); i++) arr[i] = 0;",
        "initialize(arr, 0);"
      ],
      "answer": "memset(arr, 0, sizeof(arr));"
    },
    {
      "question": "Which of the following functions is used to find the number of elements in an array in C?",
      "options": [
        "elements(arr);",
        "length(arr);",
        "count(arr);",
        "sizeof(arr) / sizeof(arr[0]);"
      ],
      "answer": "sizeof(arr) / sizeof(arr[0]);"
    },
    {
      "question": "What is the correct way to pass an array 'arr' to a function 'func' in C?",
      "options": [
        "func(arr);",
        "func(&arr);",
        "func(*arr);",
        "func(arr[]);"
      ],
      "answer": "func(arr);"
    },
    {
      "question": "Which of the following is a correct declaration of a two-dimensional array named 'matrix' with 3 rows and 4 columns in C?",
      "options": [
        "int matrix[3][4];",
        "int matrix[4][3];",
        "int matrix[3, 4];",
        "int matrix[4, 3];"
      ],
      "answer": "int matrix[3][4];"
    },
    {
      "question": "What is the result of the expression in C: arr[3] = 10; if arr is an array of integers?",
      "options": [
        "The fourth element of arr is set to 10.",
        "The third element of arr is set to 10.",
        "An error occurs because array indices start from 0.",
        "The first element of arr is set to 10."
      ],
      "answer": "The fourth element of arr is set to 10."
    },
    {
      "question": "Which of the following statements correctly assigns the value 5 to the first element of an array named 'nums' in C?",
      "options": [
        "nums[0] = 5;",
        "nums(1) = 5;",
        "nums = 5;",
        "nums = {5};"
      ],
      "answer": "nums[0] = 5;"
    }
  ]
  