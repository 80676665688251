import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        
        <div className="footer-column">
          <ul>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/disclaimer">Disclaimer</a></li>
            <li><a href="/terms-of-service">Terms of Service</a></li>
            <li><a href="/contactus">Contact Us</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} MCQKIT.COM. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
