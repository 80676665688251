export const C_Constant_set5=
[
    {
        "question": "What is the value of the following expression in C: 12 / 5?",
        "options": [
            "2.4",
            "2",
            "2.5",
            "2.3"
        ],
        "answer": "2"
    },
    {
        "question": "Which of the following is a valid way to represent a binary constant in C?",
        "options": [
            "0xF1",
            "0b1010",
            "27H",
            "037"
        ],
        "answer": "0b1010"
    },
    {
        "question": "What is the value of the following expression in C: 'C' - 'A'?",
        "options": [
            "1",
            "-1",
            "C",
            "67"
        ],
        "answer": "2"
    },
    {
        "question": "Which escape sequence is used to represent a newline character in C?",
        "options": [
            "\\n",
            "\\t",
            "\\r",
            "\\b"
        ],
        "answer": "\\n"
    },
    {
        "question": "Which of the following is NOT a valid way to represent an octal constant in C?",
        "options": [
            "092",
            "037",
            "0xFF",
            "0o777"
        ],
        "answer": "0xFF"
    },
    {
        "question": "What is the value of the following expression in C: 5.5 * 2?",
        "options": [
            "11",
            "10",
            "11.0",
            "10.0"
        ],
        "answer": "11.0"
    },
    {
        "question": "Which header file is required to use the `EOF` constant in C?",
        "options": [
            "<stdio.h>",
            "<stdlib.h>",
            "<math.h>",
            "<limits.h>"
        ],
        "answer": "<stdio.h>"
    },
    {
        "question": "Which of the following is a valid character constant in C?",
        "options": [
            "'C'",
            "'\\n'",
            "'12'",
            "'\\t'"
        ],
        "answer": "'C'"
    },
    {
        "question": "What is the value of the following expression in C: sizeof(long long)?",
        "options": [
            "4",
            "8",
            "16",
            "Depends on the compiler"
        ],
        "answer": "Depends on the compiler"
    },
    {
        "question": "Which of the following is NOT a valid constant type in C programming?",
        "options": [
            "Integer constant",
            "Floating-point constant",
            "Character constant",
            "Dynamic constant"
        ],
        "answer": "Dynamic constant"
    }
]
