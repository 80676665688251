export const C_Constant_set3=
[
    {
        "question": "What is the value of the following expression in C: 2 * (5 + 3)?",
        "options": [
            "16",
            "10",
            "13",
            "18"
        ],
        "answer": "16"
    },
    {
        "question": "Which of the following is a valid way to represent a hexadecimal constant in C?",
        "options": [
            "0x2FG",
            "0xC5",
            "27H",
            "0b1010"
        ],
        "answer": "0xC5"
    },
    {
        "question": "What is the value of the following expression in C: 'A' - 'B'?",
        "options": [
            "1",
            "-1",
            "B",
            "65"
        ],
        "answer": "-1"
    },
    {
        "question": "Which escape sequence is used to represent a tab character in C?",
        "options": [
            "\\t",
            "\\n",
            "\\r",
            "\\b"
        ],
        "answer": "\\t"
    },
    {
        "question": "Which of the following is NOT a valid way to represent a floating-point constant in C?",
        "options": [
            "3.14",
            "6.022e23",
            "0xFF",
            "0.1f"
        ],
        "answer": "0xFF"
    },
    {
        "question": "What is the value of the following expression in C: 10 / 3.0?",
        "options": [
            "3",
            "3.33",
            "3.0",
            "3.333"
        ],
        "answer": "3.33"
    },
    {
        "question": "Which header file is required to use the `EXIT_SUCCESS` constant in C?",
        "options": [
            "<stdio.h>",
            "<stdlib.h>",
            "<math.h>",
            "<limits.h>"
        ],
        "answer": "<stdlib.h>"
    },
    {
        "question": "Which of the following is a valid character constant in C?",
        "options": [
            "'A'",
            "'\\n'",
            "'12'",
            "'\\t'"
        ],
        "answer": "'A'"
    },
    {
        "question": "What is the value of the following expression in C: sizeof(long double)?",
        "options": [
            "4",
            "8",
            "16",
            "Depends on the compiler"
        ],
        "answer": "Depends on the compiler"
    },
    {
        "question": "Which of the following is NOT a valid constant type in C programming?",
        "options": [
            "Integer constant",
            "Floating-point constant",
            "Character constant",
            "Dynamic constant"
        ],
        "answer": "Dynamic constant"
    }
]
