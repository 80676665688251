// questionsData.js

export const Operators_set8_data = [
    {
        "question": "What is the bitwise AND operator in C?",
        "options": ["&", "|", "~", "^"],
        "answer": "&"
    },
    {
        "question": "Which operator is used to perform modulus operation in C?",
        "options": ["%", "/", "*", "+"],
        "answer": "%"
    },
    {
        "question": "What is the relational operator used to check if two values are not equal in C?",
        "options": ["==", "!=", "<", ">"],
        "answer": "!="
    },
    {
        "question": "Which operator is used to perform division in C?",
        "options": ["%", "/", "*", "+"],
        "answer": "/"
    },
    {
        "question": "What is the bitwise OR operator in C?",
        "options": ["&", "|", "~", "^"],
        "answer": "|"
    },
    {
        "question": "Which operator is used to perform multiplication in C?",
        "options": ["%", "/", "*", "+"],
        "answer": "*"
    },
    {
        "question": "What is the logical NOT operator in C?",
        "options": ["&&", "||", "!", "&"],
        "answer": "!"
    },
    {
        "question": "Which operator is used to compare two values for equality in C?",
        "options": ["=", "==", "!=", "+="],
        "answer": "=="
    },
    {
        "question": "What is the increment operator in C?",
        "options": ["++", "--", "+=", "-="],
        "answer": "++"
    },
    {
        "question": "Which operator is used to access the address of a variable in C?",
        "options": ["*", "&", "!", "->"],
        "answer": "&"
    }
];