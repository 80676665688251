// questionsData.js

export const Variable_set7_data = 
[
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 10;\n  printf(\"%d\", x / 3);\n  return 0;\n}\n```",
        "options": ["3", "3.33", "3.0", "Compiler error"],
        "answer": "3"
    },
    {
        "question": "Which of the following is not a valid way to initialize an array in C?",
        "options": ["int arr[5] = {1, 2, 3, 4, 5};", "int arr[] = {1, 2, 3};", "int arr[3] = {1};", "int arr[3] = {0};"],
        "answer": "int arr[3] = {0};"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  float x = 5.25;\n  printf(\"%f\", x);\n  return 0;\n}\n```",
        "options": ["5.25", "5", "5.0", "Compiler error"],
        "answer": "5.25"
    },
    {
        "question": "What is the purpose of the 'do-while' loop in C?",
        "options": ["To execute a block of code repeatedly", "To skip the current iteration of a loop", "To exit from a loop or switch statement", "To declare a variable"],
        "answer": "To execute a block of code repeatedly"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  char ch = 'A';\n  printf(\"%c\", ch + 1);\n  return 0;\n}\n```",
        "options": ["A", "B", "66", "Compiler error"],
        "answer": "B"
    },
    {
        "question": "What is the purpose of the 'const' keyword in C?",
        "options": ["To declare a constant variable", "To allocate memory dynamically", "To specify the scope of a variable", "To define a function"],
        "answer": "To declare a constant variable"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5, y = 2;\n  printf(\"%d\", x / y);\n  return 0;\n}\n```",
        "options": ["2", "2.5", "3", "Compiler error"],
        "answer": "2"
    },
    {
        "question": "What is the purpose of the 'sizeof' operator in C?",
        "options": ["To find the size of a variable", "To assign a value to a variable", "To compare two variables", "To execute a block of code repeatedly"],
        "answer": "To find the size of a variable"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  printf(\"%c\", 'A' + 1);\n  return 0;\n}\n```",
        "options": ["A", "B", "66", "Compiler error"],
        "answer": "B"
    },
    {
        "question": "What is the purpose of the 'break' statement in C?",
        "options": ["To end the program execution", "To skip the current iteration of a loop", "To exit from a loop or switch statement", "To return a value from a function"],
        "answer": "To exit from a loop or switch statement"
    }
];
  