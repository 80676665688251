export const GS_S7_data=[
    
  {
    "question": "இந்தியாவின் முதல் மன்னர் யார்?",
    "options": ["அஷோகன்", "சாமுத்திரன்", "அக்னி", "இளங்கோ வள்ளுவர்"],
    "answer": "சாமுத்திரன்"
},
{
    "question": "இந்தியாவின் முதல் பொறியாளர் யார்?",
    "options": ["தாராஸ்த் பெட்டி", "ரத்தகீரி கோதிப்பில்", "அம்பேட்கர்", "ரவீந்திர நாத் டாகோர்"],
    "answer": "ரத்தகீரி கோதிப்பில்"
},
{
    "question": "இந்தியாவின் முதல் புதிய செல்வரும் யார்?",
    "options": ["சிவாஜி", "சாமுத்திரன்", "ராணி ராஜ்யம்மா", "மஹாத்மா காந்தி"],
    "answer": "சிவாஜி"
},
{
    "question": "இந்தியாவின் முதல் கலைஞர் யார்?",
    "options": ["சாமுத்திரன்", "ரத்தகீரி கோதிப்பில்", "மஹாத்மா காந்தி", "சிவாஜி"],
    "answer": "சிவாஜி"
},
{
    "question": "இந்தியாவின் முதல் தலைவர் யார்?",
    "options": ["ஜனநாயகர் பெரியார்", "ரத்தகீரி கோதிப்பில்", "மஹாத்மா காந்தி", "சிவாஜி"],
    "answer": "ஜனநாயகர் பெரியார்"
},
{
  "question": "இந்தியாவின் புதிய மன்னர் யார்?",
  "options": ["ராஜிவ் குமார்", "நரேந்திர மோடி", "பிரணப் முகுந்த்", "மண்டேலா"],
  "answer": "நரேந்திர மோடி"
},
{
  "question": "இந்தியாவின் முதல் திரைப்படம் எது?",
  "options": ["கத்தி கால்", "முள்ளுப் பூஜை", "ராஜா விஜயம்", "செல்லமே செல்லம்"],
  "answer": "ராஜா விஜயம்"
},
{
  "question": "இந்தியாவின் முதல் பொறியாளர் யார்?",
  "options": ["தாராஸ்த் பெட்டி", "ரத்தகீரி கோதிப்பில்", "அம்பேட்கர்", "ரவீந்திர நாத் டாகோர்"],
  "answer": "அம்பேட்கர்"
},
{
  "question": "இந்தியாவின் பின்னணி முதல் நியாயம் யார்?",
  "options": ["மகாத்மா காந்தி", "ரத்தகீரி கோதிப்பில்", "தேசிய பட்டாளிகள்", "ஜனநாயகர் பெரியார்"],
  "answer": "ரத்தகீரி கோதிப்பில்"
},
{
  "question": "இந்தியாவின் முதல் மர்ம பொருளாளர் யார்?",
  "options": ["அகிலாண்டா", "சின்ன அசியா", "வெள்ளாந்திரம்", "சிக்ககோவில்"],
  "answer": "சின்ன அசியா"
}
              
      
];
