export const Java_Inheritance_data_2=[
    {
        "question": "Which of the following statements about inheritance in Java is true?",
        "options": ["A subclass inherits all fields and methods (except private ones) from its superclass", "A superclass inherits all fields and methods (except private ones) from its subclass", "Inheritance allows a class to inherit from multiple superclasses in Java", "In Java, inheritance can only occur between classes in the same package"],
        "answer": "a) A subclass inherits all fields and methods (except private ones) from its superclass"
    },
    {
        "question": "Which keyword is used to prevent inheritance in Java?",
        "options": ["final", "static", "private", "protected"],
        "answer": "a) final"
    },
    {
        "question": "What is the term used to describe the relationship between a superclass and its subclass?",
        "options": ["Parent-child relationship", "Sibling relationship", "Unrelated relationship", "Friendship"],
        "answer": "a) Parent-child relationship"
    },
    {
        "question": "Which of the following is true about constructors in inheritance?",
        "options": ["A subclass constructor cannot call a constructor from its superclass", "A superclass constructor cannot be overridden by a subclass", "A subclass constructor must always have the same name as its superclass constructor", "A superclass constructor is automatically called when a subclass object is created"],
        "answer": "d) A superclass constructor is automatically called when a subclass object is created"
    },
    {
        "question": "In Java, can a subclass inherit private members (fields and methods) from its superclass?",
        "options": ["Yes", "No"],
        "answer": "b) No"
    },
    {
        "question": "Which of the following keywords is used to access members of the superclass from the subclass?",
        "options": ["super", "this", "extends", "implements"],
        "answer": "a) super"
    },
    {
        "question": "Which of the following is true about method overriding in Java?",
        "options": ["A subclass can override a static method of its superclass", "A subclass cannot override a final method of its superclass", "A subclass can change the return type of the overridden method", "A subclass can override a private method of its superclass"],
        "answer": "b) A subclass cannot override a final method of its superclass"
    },
    {
        "question": "Which of the following statements is true regarding method hiding in Java?",
        "options": ["Method hiding occurs when a static method in a subclass has the same name and signature as a static method in its superclass", "Method hiding occurs when a static method in a superclass has the same name and signature as a non-static method in its subclass", "Method hiding occurs when a non-static method in a subclass has the same name and signature as a non-static method in its superclass", "Method hiding is not supported in Java"],
        "answer": "a) Method hiding occurs when a static method in a subclass has the same name and signature as a static method in its superclass"
    },
    {
        "question": "What is the purpose of method overriding in Java?",
        "options": ["To create new methods in the subclass", "To replace the implementation of a method in the superclass", "To hide methods from the superclass", "To prevent methods from being inherited by subclasses"],
        "answer": "b) To replace the implementation of a method in the superclass"
    },
    {
        "question": "Which of the following is an advantage of inheritance in Java?",
        "options": ["It allows code reusability", "It increases encapsulation", "It reduces code complexity", "It decreases the size of the compiled code"],
        "answer": "a) It allows code reusability"
    }
]
