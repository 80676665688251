// questionsData.js

export const Variable_set3_data = 
[
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5, y = 10;\n  printf(\"%d\", x * y);\n  return 0;\n}\n```",
        "options": ["50", "5", "10", "Compiler error"],
        "answer": "50"
    },
    {
        "question": "Which of the following is a valid variable name in C?",
        "options": ["123variable", "variable@123", "_variable", "variable 123"],
        "answer": "_variable"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 10, y = 3;\n  printf(\"%d\", x % y);\n  return 0;\n}\n```",
        "options": ["3", "3.33", "1", "Compiler error"],
        "answer": "1"
    },
    {
        "question": "Which of the following is not a valid variable declaration in C?",
        "options": ["int x;", "int x = 10;", "x = 10;", "int x, y;"],
        "answer": "x = 10;"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5, y = 2;\n  printf(\"%d\", x - y);\n  return 0;\n}\n```",
        "options": ["3", "7", "2", "Compiler error"],
        "answer": "3"
    },
    {
        "question": "What is the purpose of the 'const' keyword in C?",
        "options": ["To declare a constant variable", "To allocate memory dynamically", "To specify the scope of a variable", "To define a function"],
        "answer": "To declare a constant variable"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  const int x = 5;\n  printf(\"%d\", x);\n  return 0;\n}\n```",
        "options": ["5", "Compiler error", "Garbage value", "Undefined"],
        "answer": "5"
    },
    {
        "question": "Which keyword is used to declare a constant variable in C?",
        "options": ["constant", "const", "fixed", "define"],
        "answer": "const"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5;\n  printf(\"%d\", x++);\n  return 0;\n}\n```",
        "options": ["4", "5", "6", "Compiler error"],
        "answer": "5"
    },
    {
        "question": "Which of the following is a valid variable declaration in C?",
        "options": ["float x = 3.14;", "x = 3.14;", "double x;", "int x();"],
        "answer": "float x = 3.14;"
    }
]

;
  