// NavigationBar.js

import React from 'react';
import './NavigationBar.css'; // Import CSS file for styling

const NavigationBar = () => {
  return (
   <div>
    <nav className="navbars">
      <div className="navbar-container">
        <div className="logo">MCQ KIT</div>
        <div className="links">
          <a href="/">Home</a>
          <a href="/about">About</a>
          <a href="/contactus">Contact</a>
         
        </div>
      </div>
    </nav>
    </div>
  );
};

export default NavigationBar;
