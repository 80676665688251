export const C_String_set3=[
  {
    "question": "Which function is used to convert a string to an integer in C?",
    "options": [
      "atoi()",
      "strtoi()",
      "intstr()",
      "strtoint()"
    ],
    "answer": "atoi()"
  },
  {
    "question": "What does the `strchr()` function return if the character is not found in the string?",
    "options": [
      "NULL",
      "0",
      "-1",
      "Empty string"
    ],
    "answer": "NULL"
  },
  {
    "question": "How do you check if a string contains only numeric characters in C?",
    "options": [
      "isdigit()",
      "isnum()",
      "isnumeric()",
      "isnumber()"
    ],
    "answer": "isnumeric()"
  },
  {
    "question": "Which function is used to convert a string to a double in C?",
    "options": [
      "atof()",
      "strtof()",
      "doublestr()",
      "strtodouble()"
    ],
    "answer": "atof()"
  },
  {
    "question": "What does the `strcspn()` function do in C?",
    "options": [
      "Returns the length of the initial segment of the string that does not contain any of the characters specified in another string",
      "Concatenates two strings",
      "Searches for the first occurrence of a character in a string",
      "Compares two strings"
    ],
    "answer": "Returns the length of the initial segment of the string that does not contain any of the characters specified in another string"
  },
  {
    "question": "How do you convert a string to a long integer in C?",
    "options": [
      "strtol()",
      "atol()",
      "longstr()",
      "strtolong()"
    ],
    "answer": "strtol()"
  },
  {
    "question": "Which function is used to convert a string to lowercase in C?",
    "options": [
      "tolower()",
      "lower()",
      "strlower()",
      "strtolower()"
    ],
    "answer": "strlower()"
  },
  {
    "question": "What is the correct syntax to initialize a string in C?",
    "options": [
      "char str = \"Hello\";",
      "char str[] = \"Hello\";",
      "string str = \"Hello\";",
      "string str[] = \"Hello\";"
    ],
    "answer": "char str[] = \"Hello\";"
  },
  {
    "question": "How do you check if two strings are equal in C?",
    "options": [
      "str1 == str2",
      "strcmp(str1, str2) == 0",
      "strcompare(str1, str2) == 0",
      "compare(str1, str2) == 0"
    ],
    "answer": "strcmp(str1, str2) == 0"
  },
  {
    "question": "What is the correct way to declare and initialize an empty string in C?",
    "options": [
      "char str = \"\";",
      "char str[0];",
      "char* str = \"\";",
      "char str[] = \"\";"
    ],
    "answer": "char str[] = \"\";"
  }
]
