import React from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import NavigationBar from '../Navbar/NavigationBar';
import Footer from '../Footer/Footer';
import './contactus.css'


const ContactUs = () => {
  return (
    <div>
    <NavigationBar/>
    <div className="container-contactus">
    <Container>
      <h1>Contact Us</h1>
      <Form>
        <Form.Group controlId="formBasicName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Enter your name" />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" rows={3} placeholder="Enter your message" />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
    </div>

    <Footer/>
    </div>
  );
}

export default ContactUs;
