export const GS_S8_data=[
    
  {
    "question": "தமிழ் மொழியின் தலைமை காலத்தில் அதன் தலைமை குறியிடம் யாது?",
    "options": ["பழனி", "கொல்லாமன்", "போகோ", "மானிக்கவாசகர்"],
    "answer": "பழனி"
},
{
    "question": "பதினொன்றாம் நூற்றாண்டில் தமிழ்நாட்டின் முதல் தலைவர் யார்?",
    "options": ["மு.கருணாநிதி", "கழகச் செயலாளர் மக்கள் முரசொலி முதல் தலைவர் மு.கருணாநிதி", "எம்.ஜி.ஆர் நெதுன்கோட்டை மக்கள் முரசொலி முதல் தலைவர் மு.கருணாநிதி", "தலைவர் குப்புசாமி முதல் தலைவர் மு.கருணாநிதி"],
    "answer": "கழகச் செயலாளர் மக்கள் முரசொலி முதல் தலைவர் மு.கருணாநிதி"
},
{
    "question": "இந்திய அரசு அனுமதி பெற்ற மேற்கோளிகளில் எது தலைவராக இருந்தார்?",
    "options": ["கனிமொழி", "நாசிருத்தின்", "கன்யாகுமாரி", "ரமணாதாஸ்"],
    "answer": "ரமணாதாஸ்"
},
{
    "question": "முகமது காசிம் என்ற தலைமை காலத்தில் எது பதினாறாம் நூற்றாண்டின் முதல் மன்னர் ஆக இருந்தது?",
    "options": ["மதுரைப் பண்டியன்", "முதல்வர் ஆனந்த ராயர்", "குமாரசாமி காமராஜர்", "பண்டிய ராணி மீனாட்சி சுந்தர பாண்டியன்"],
    "answer": "பண்டிய ராணி மீனாட்சி சுந்தர பாண்டியன்"
},
{
    "question": "அக்கிரேஷன் சாதனை என்ன?",
    "options": ["பெரும்பாகனை கொண்ட சாதனை", "தனிமைப்படுத்தும் சாதனை", "தாழ்ப்பளித்து சாதியும் சாதனையும் தொடர்புடைய சாதனை", "அனைத்தும் சாதிக்கும் சாதனை"],
    "answer": "அனைத்தும் சாதிக்கும் சாதனை"
},
{
    "question": "தொல்காப்பியத்தின் நூல்களில் எது முதன்மை?",
    "options": ["சிலப்பதிகாரம்", "மணிமேகலை", "நாணம் திருமுருகாற்றுப் பாதம்", "சித்திரக்கோவை"],
    "answer": "சிலப்பதிகாரம்"
},
{
    "question": "கவி விடுதலைவர் பட்டயத்தில் எது முதன்மை?",
    "options": ["அமரவாசியின் திருக்குறள்", "மணிக்கோவை", "நாணம் திருமுருகாற்றுப் பாதம்", "திருக்குறள்"],
    "answer": "திருக்குறள்"
},
{
    "question": "எத்தனை அரசியல் முதல்வர்கள் சர்வதேச செயலாளர் பதவியில் இருந்துள்ளனர்?",
    "options": ["ஒருவர்", "இரண்டு வேலையில்", "மூன்று", "நான்கு"],
    "answer": "நான்கு"
},
{
    "question": "பழைய கல்லறைகள் யாது?",
    "options": ["கோமெட்டி", "பண்டிய வீரர்", "செல்வ ராயன்", "கோல வீரர்"],
    "answer": "பண்டிய வீரர்"
},
{
  "question": "சர்வதேச மாநிலமாகும் 'நியூ இந்தியா' கோட்டை எங்கு அமைந்துள்ளது?",
  "options": ["தஞ்சாவூர்", "மதுரை", "கோவை", "சென்னை"],
  "answer": "தஞ்சாவூர்"
}

              
      
];
