export const GS_S6_data=[
    
    {
        "question": "இந்தியாவின் கொள்கை அமைப்பின் பெயர் என்ன?",
        "options": ["இடது முன்னிலை கூட்டுக்குழு", "இந்தியன் நேரில் தொகுதி", "இந்திய முன்னிலை பூர்வகுணம்", "இந்தியன் முன்னிலை பூர்வகுணம்"],
        "answer": "இந்தியன் முன்னிலை பூர்வகுணம்"
    },
    {
        "question": "இந்தியாவின் பெரிய குளம் எங்கு அமைந்துள்ளது?",
        "options": ["அண்ணா நகர்", "கோவை", "சென்னை", "மதுரை"],
        "answer": "அண்ணா நகர்"
    },
    {
        "question": "இந்தியாவின் முதல் தலைமை தகவல் மையம் எது?",
        "options": ["இந்திய சங்கம்", "மதுரை மண்டலசபை", "சென்னை மண்டலசபை", "திருச்சிராப்பள்ளி மண்டலசபை"],
        "answer": "இந்திய சங்கம்"
    },
    {
        "question": "இந்தியாவில் முதல் வேலையாளர் சங்கம் எங்கு அமைந்துள்ளது?",
        "options": ["திருநெல்வேலி", "கோவை", "சென்னை", "மதுரை"],
        "answer": "சென்னை"
    },
    {
        "question": "இந்தியாவின் அதிசய கோட்டை எங்கு அமைந்துள்ளது?",
        "options": ["தஞ்சாவூர்", "மதுரை", "கோவை", "சென்னை"],
        "answer": "மதுரை"
    },
    {
      "question": "பெரிய நகரமான மொட்டைக்கட்டை எங்கு அமைந்துள்ளது?",
      "options": ["சென்னை", "கோவை", "மதுரை", "மதுரை"],
      "answer": "சென்னை"
  },
  {
      "question": "சென்னையில் அரசியல் சங்கம் எங்கு அமைந்துள்ளது?",
      "options": ["அண்ணா நகர்", "கோவை", "சென்னை", "மதுரை"],
      "answer": "சென்னை"
  },
  {
      "question": "தமிழ்நாட்டில் உள்ள பெரிய முட்செடி எங்கு அமைந்துள்ளது?",
      "options": ["மதுரை", "மதுரை மண்டலசபை", "அண்ணா நகர்", "சென்னை"],
      "answer": "மதுரை"
  },
  {
      "question": "இந்தியாவின் மொத்த பெரிய முன்னிலை நகரம் எது?",
      "options": ["தில்லி", "கோவை", "சென்னை", "மதுரை"],
      "answer": "தில்லி"
  },
  {
      "question": "கடல் சூரியன் எங்கு அமைந்துள்ளது?",
      "options": ["கோவை", "சென்னை", "மதுரை", "மதுரை"],
      "answer": "கோவை"
  }

      
];
