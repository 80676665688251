export const C_Constant_set4=
[
    {
        "question": "What is the value of the following expression in C: 10 % 3?",
        "options": [
            "1",
            "3.33",
            "0.3",
            "0.33"
        ],
        "answer": "1"
    },
    {
        "question": "Which of the following is a valid way to represent an octal constant in C?",
        "options": [
            "092",
            "037",
            "0xFF",
            "0o777"
        ],
        "answer": "037"
    },
    {
        "question": "What is the value of the following expression in C: 'B' - 'A'?",
        "options": [
            "1",
            "-1",
            "A",
            "66"
        ],
        "answer": "1"
    },
    {
        "question": "Which escape sequence is used to represent a carriage return character in C?",
        "options": [
            "\\r",
            "\\n",
            "\\t",
            "\\b"
        ],
        "answer": "\\r"
    },
    {
        "question": "Which of the following is NOT a valid way to represent a floating-point constant in C?",
        "options": [
            "3.14",
            "6.022e23",
            "0xFF",
            "0.1f"
        ],
        "answer": "0xFF"
    },
    {
        "question": "What is the value of the following expression in C: 10 / 3.0?",
        "options": [
            "3",
            "3.33",
            "3.0",
            "3.333"
        ],
        "answer": "3.33"
    },
    {
        "question": "Which header file is required to use the `EXIT_FAILURE` constant in C?",
        "options": [
            "<stdio.h>",
            "<stdlib.h>",
            "<math.h>",
            "<errno.h>"
        ],
        "answer": "<stdlib.h>"
    },
    {
        "question": "Which of the following is a valid character constant in C?",
        "options": [
            "'B'",
            "'\\n'",
            "'12'",
            "'\\t'"
        ],
        "answer": "'B'"
    },
    {
        "question": "What is the value of the following expression in C: sizeof(long double)?",
        "options": [
            "4",
            "8",
            "16",
            "Depends on the compiler"
        ],
        "answer": "Depends on the compiler"
    },
    {
        "question": "Which of the following is NOT a valid constant type in C programming?",
        "options": [
            "Integer constant",
            "Floating-point constant",
            "Character constant",
            "Dynamic constant"
        ],
        "answer": "Dynamic constant"
    }
]
