// questionsData.js

export const Expression_set2_data = [
      {
          "question": "What will be the output of the expression 8 + 5 * 2?",
          "options": ["26", "18", "21", "13"],
          "answer": "18"
      },
      {
          "question": "Which operator is used for subtraction in C?",
          "options": ["+", "-", "*", "/"],
          "answer": "-"
      },
      {
          "question": "What is the result of the expression 25 / 4 in C?",
          "options": ["6", "6.25", "5", "6.5"],
          "answer": "6"
      },
      {
          "question": "What is the value of the expression (9 - 3) * 2?",
          "options": ["18", "12", "9", "15"],
          "answer": "12"
      },
      {
          "question": "Which operator is used for multiplication in C?",
          "options": ["+", "-", "*", "/"],
          "answer": "*"
      },
      {
          "question": "What is the result of the expression 16 % 5 in C?",
          "options": ["2", "3", "1", "4"],
          "answer": "1"
      },
      {
          "question": "What is the value of the expression 2 + 3 * (4 - 1)?",
          "options": ["11", "9", "14", "8"],
          "answer": "11"
      },
      {
          "question": "Which operator is used for division in C?",
          "options": ["+", "-", "*", "/"],
          "answer": "/"
      },
      {
          "question": "What is the result of the expression 12 / 7 in C?",
          "options": ["1", "1.5", "2", "1.7"],
          "answer": "1"
      },
      {
          "question": "What is the value of the expression (20 - 4) % 5?",
          "options": ["4", "3", "2", "1"],
          "answer": "1"
      }
  ];
  