export const C_Array_set5=[
    {
      "question": "Which of the following is true about passing arrays as function arguments in C?",
      "options": [
        "Arrays are always passed by value.",
        "Arrays are always passed by reference.",
        "Arrays can be passed by value or by reference.",
        "Arrays cannot be passed as function arguments."
      ],
      "answer": "Arrays are always passed by reference."
    },
    {
      "question": "What is the correct way to declare and initialize a two-dimensional array 'table' with 3 rows and 2 columns in C?",
      "options": [
        "int table[3][2];",
        "int table[][] = {{1, 2}, {3, 4}, {5, 6}};",
        "int table[2][3];",
        "int table[3, 2];"
      ],
      "answer": "int table[3][2];"
    },
    {
      "question": "What is the output of the following code in C? int arr[5] = {1, 2, 3, 4, 5}; printf(\"%d\", arr[4]);",
      "options": [
        "1",
        "2",
        "5",
        "Garbage value"
      ],
      "answer": "Garbage value"
    },
    {
      "question": "Which function is used to compare two arrays in C?",
      "options": [
        "strcmp()",
        "compare()",
        "memcmp()",
        "equals()"
      ],
      "answer": "memcmp()"
    },
    {
      "question": "What is the result of the expression 'sizeof(arr)' if 'arr' is an array of 10 integers in C?",
      "options": [
        "10",
        "40",
        "4",
        "Error"
      ],
      "answer": "40"
    },
    {
      "question": "Which of the following is true about array indexing in C?",
      "options": [
        "Arrays can only be indexed using integers.",
        "Arrays can be indexed using floating-point numbers.",
        "Arrays can be indexed using both positive and negative integers.",
        "Arrays cannot be indexed using variables."
      ],
      "answer": "Arrays can only be indexed using integers."
    },
    {
      "question": "What is the correct way to declare a two-dimensional array 'matrix' with 2 rows and 3 columns in C?",
      "options": [
        "int matrix[2][3];",
        "int matrix[3][2];",
        "int matrix[][] = {{1, 2, 3}, {4, 5, 6}};",
        "int matrix[2, 3];"
      ],
      "answer": "int matrix[2][3];"
    },
    {
      "question": "What is the result of the expression 'sizeof(matrix)' if 'matrix' is a two-dimensional array of integers with 3 rows and 4 columns in C?",
      "options": [
        "12",
        "16",
        "48",
        "Error"
      ],
      "answer": "48"
    },
    {
      "question": "Which of the following statements is true about multi-dimensional arrays in C?",
      "options": [
        "All dimensions must have the same size.",
        "They can have different sizes for each dimension.",
        "They can only have two dimensions.",
        "They cannot be accessed using indices."
      ],
      "answer": "All dimensions must have the same size."
    },
    {
      "question": "Which function is used to copy the contents of one array to another in C?",
      "options": [
        "memcpy()",
        "copy()",
        "move()",
        "strcpy()"
      ],
      "answer": "memcpy()"
    }
  ]
  