// questionsData.js

export const Variable_set5_data = 
[
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5, y = 3;\n  printf(\"%d\", x / y);\n  return 0;\n}\n```",
        "options": ["1", "1.666", "2", "Compiler error"],
        "answer": "1"
    },
    {
        "question": "Which of the following is not a valid relational operator in C?",
        "options": ["==", "!=", ">", "<=", "=>"],
        "answer": "=>"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5;\n  printf(\"%d\", x--);\n  return 0;\n}\n```",
        "options": ["4", "5", "6", "Compiler error"],
        "answer": "5"
    },
    {
        "question": "What is the purpose of the '&&' operator in C?",
        "options": ["Logical OR", "Logical AND", "Bitwise OR", "Bitwise AND"],
        "answer": "Logical AND"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5;\n  printf(\"%d\", x > 3 ? 1 : 0);\n  return 0;\n}\n```",
        "options": ["1", "0", "5", "Compiler error"],
        "answer": "1"
    },
    {
        "question": "What is the purpose of the '||' operator in C?",
        "options": ["Logical OR", "Logical AND", "Bitwise OR", "Bitwise AND"],
        "answer": "Logical OR"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5;\n  printf(\"%d\", x < 3 ? 1 : 0);\n  return 0;\n}\n```",
        "options": ["1", "0", "5", "Compiler error"],
        "answer": "0"
    },
    {
        "question": "What is the purpose of the '!' operator in C?",
        "options": ["Logical NOT", "Logical AND", "Bitwise OR", "Bitwise AND"],
        "answer": "Logical NOT"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5;\n  printf(\"%d\", x != 3 ? 1 : 0);\n  return 0;\n}\n```",
        "options": ["1", "0", "5", "Compiler error"],
        "answer": "1"
    },
    {
        "question": "What is the purpose of the 'sizeof' operator in C?",
        "options": ["To return the size of a variable", "To return the memory address of a variable", "To allocate memory dynamically", "To specify the scope of a variable"],
        "answer": "To return the size of a variable"
    }
]

;
  