export const C_String_set4=[
  {
    "question": "Which function is used to find the last occurrence of a substring in a string?",
    "options": [
      "strstr()",
      "strrstr()",
      "strfindlast()",
      "findlastsub()"
    ],
    "answer": "strrstr()"
  },
  {
    "question": "How do you extract a substring from a string in C?",
    "options": [
      "substr()",
      "strsub()",
      "strncpy()",
      "strcopy()"
    ],
    "answer": "strncpy()"
  },
  {
    "question": "What is the correct way to append a character to a string in C?",
    "options": [
      "strcat(str, ch)",
      "strappend(str, ch)",
      "strncat(str, ch, 1)",
      "strconcat(str, ch)"
    ],
    "answer": "strncat(str, ch, 1)"
  },
  {
    "question": "Which function is used to convert an integer to a string in C?",
    "options": [
      "itoa()",
      "intstr()",
      "tostring()",
      "intostr()"
    ],
    "answer": "itoa()"
  },
  {
    "question": "What does the `strpbrk()` function do in C?",
    "options": [
      "Returns a pointer to the last occurrence of a character in a string",
      "Searches for a set of characters in a string",
      "Returns the length of the initial segment of the string that contains only characters from another string",
      "Compares two strings"
    ],
    "answer": "Searches for a set of characters in a string"
  },
  {
    "question": "How do you check if a string is empty in C?",
    "options": [
      "strlen(str) == 0",
      "str == \"\"",
      "strcmp(str, \"\") == 0",
      "strempty(str)"
    ],
    "answer": "strlen(str) == 0"
  },
  {
    "question": "What is the correct way to compare two strings in C ignoring their case?",
    "options": [
      "stricmp(str1, str2)",
      "strcmp(str1, str2)",
      "strcasecmp(str1, str2)",
      "strcompare(str1, str2)"
    ],
    "answer": "strcasecmp(str1, str2)"
  },
  {
    "question": "Which function is used to find the first occurrence of any character in a string that is in another string?",
    "options": [
      "strcspn()",
      "strspn()",
      "strpbrk()",
      "strfindany()"
    ],
    "answer": "strpbrk()"
  },
  {
    "question": "How do you trim leading and trailing whitespace from a string in C?",
    "options": [
      "trim()",
      "strtrim()",
      "rtrim()",
      "strchomp()"
    ],
    "answer": "strtrim()"
  },
  {
    "question": "What is the correct way to convert a string to a lowercase in place in C?",
    "options": [
      "strlower()",
      "tolower()",
      "strtolower()",
      "strlwr()"
    ],
    "answer": "strlwr()"
  }
]
