// questionsData.js

export const Functions_data = [
    {
        "question": "What is a function in C?",
        "options": ["A block of code that is executed repeatedly", "A block of code that makes decisions based on conditions", "A block of code that defines a set of instructions to perform a specific task", "A block of code that declares variables"],
        "answer": "A block of code that defines a set of instructions to perform a specific task"
    },
    {
        "question": "What is the purpose of a function prototype in C?",
        "options": ["To declare a function before its definition", "To define a function before its declaration", "To execute a function", "To declare variables"],
        "answer": "To declare a function before its definition"
    },
    {
        "question": "Which of the following is the correct syntax to declare a function in C?",
        "options": ["int functionName();", "functionName(int);", "int functionName(int);", "functionName;"],
        "answer": "int functionName(int);"
    },
    {
        "question": "What is the return type of a function in C?",
        "options": ["The type of the first argument", "The type of the last argument", "The type of the variable declared in the function", "The type of the value returned by the function"],
        "answer": "The type of the value returned by the function"
    },
    {
        "question": "Which of the following is a valid function call in C?",
        "options": ["functionName;", "functionName();", "int functionName();", "functionName(int);"],
        "answer": "functionName();"
    },
    {
        "question": "What is the purpose of the 'return' statement in C?",
        "options": ["To exit the current loop or switch-case block", "To continue to the next iteration of the loop", "To return a value from a function", "To declare variables"],
        "answer": "To return a value from a function"
    },
    {
        "question": "Which keyword is used to pass arguments to a function in C?",
        "options": ["pass", "call", "return", "void"],
        "answer": "void"
    },
    {
        "question": "What is the purpose of the 'void' keyword in a function declaration in C?",
        "options": ["To specify that the function returns nothing", "To specify that the function takes no arguments", "To declare a variable", "To declare a function"],
        "answer": "To specify that the function takes no arguments"
    },
    {
        "question": "What is the scope of a variable declared inside a function in C?",
        "options": ["Global scope", "Local scope", "Function scope", "Class scope"],
        "answer": "Local scope"
    },
    {
        "question": "What is the purpose of recursion in C functions?",
        "options": ["To declare a function", "To define a function", "To execute a function", "To call a function from within itself"],
        "answer": "To call a function from within itself"
    }
    
    // Add more questions as needed
  ];
  