export const DataType_set3_data = [
    
    {
        "question": "Which data type is used to store a collection of elements of different types in C?",
        "options": ["int", "float", "char", "struct"],
        "answer": "struct"
    },
    {
        "question": "What is the size of a 'struct' data type in C?",
        "options": ["Depends on the elements", "2 bytes", "8 bytes", "Depends on the system architecture"],
        "answer": "Depends on the elements"
    },
    {
        "question": "Which data type is used to store different data types in the same memory location in C?",
        "options": ["int", "float", "char", "union"],
        "answer": "union"
    },
    {
        "question": "What is the size of a 'union' data type in C?",
        "options": ["Size of the largest member", "Sum of member sizes", "Depends on the system architecture", "1 byte"],
        "answer": "Size of the largest member"
    },
    {
        "question": "Which data type is used to define a set of named integer constants in C?",
        "options": ["int", "float", "char", "enum"],
        "answer": "enum"
    },
    {
        "question": "What is the size of the 'enum' data type in C?",
        "options": ["Depends on the number of constants", "4 bytes", "8 bytes", "Depends on the system architecture"],
        "answer": "Depends on the number of constants"
    },
    {
        "question": "Which data type is used to store memory addresses in C?",
        "options": ["int", "float", "char", "pointer"],
        "answer": "pointer"
    },
    {
        "question": "What is the size of a pointer data type in C?",
        "options": ["4 bytes", "8 bytes", "2 bytes", "Depends on the system architecture"],
        "answer": "Depends on the system architecture"
    },
    {
        "question": "Which data type is used to represent absence of type in C?",
        "options": ["int", "float", "char", "void"],
        "answer": "void"
    },
    {
        "question": "Which data type is used to store Boolean values in C?",
        "options": ["bool", "boolean", "int", "char"],
        "answer": "int"
    }
    
    
];