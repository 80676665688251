export const GS_S5_data=[
    
  {
    "question": "இந்தியாவின் காவிரி நதியின் பெயர் பல மொழிகளில் என்ன?",
    "options": ["பெங்களி", "தமிழ்", "கன்னடம்", "சர்ஸ்கிருதம்"],
    "answer": "தமிழ்"
},
{
    "question": "வேர்கள் செய்யும் வேலையை பெருக்கியவர் யார்?",
    "options": ["பொன்னம்பல்", "திருவள்ளுவர்", "பாரதிதாஸன்", "அன்னாதுரை"],
    "answer": "திருவள்ளுவர்"
},
{
    "question": "தமிழ் எழுத்துக்களில் வரும் 'ழ' எழுத்து குறிப்பிட்ட வரையறு என்றது?",
    "options": ["எட்டு", "இரண்டு", "ஐந்து", "ஆறு"],
    "answer": "எட்டு"
},
{
    "question": "முதன்மையான தமிழ் கவிஞர் யார்?",
    "options": ["கம்பராமான்", "பாரதிதாசன்", "குமரன்", "சுப்பிரமணிய பாரதி"],
    "answer": "சுப்பிரமணிய பாரதி"
},
{
    "question": "சிங்கம் என்பது எவ்வாறு அமைந்திருக்கிறது?",
    "options": ["தேசிய காவிரி", "வண்டியில்", "முத்துச்சாரி", "ராஜ சிங்கம்"],
    "answer": "தேசிய காவிரி"
},
{
  "question": "தமிழகத்தின் மெய் நதியைக் குறிக்கும் குறியீடு எது?",
  "options": ["தமிழ்", "கன்னடம்", "இந்தி", "தெலுங்கு"],
  "answer": "தமிழ்"
},
{
  "question": "பாரதிதாசனின் பெயர் என்ன?",
  "options": ["கன்னத்திரு", "தாசன்", "கம்பராமான்", "தேவர்"],
  "answer": "கம்பராமான்"
},
{
  "question": "தமிழ்நாட்டின் சிறந்த பல்கலைக்கழகம் எது?",
  "options": ["அண்ணா பல்கலைக்கழகம்", "பாரதியார் பல்கலைக்கழகம்", "மதுரை காமராஜர் பல்கலைக்கழகம்", "திருச்சிராப்பள்ளி காமராஜர் பல்கலைக்கழகம்"],
  "answer": "அண்ணா பல்கலைக்கழகம்"
},
{
  "question": "தமிழகத்தின் அரசியல் பேரவை முழுவதும் எங்கு அமைந்துள்ளது?",
  "options": ["சென்னை", "கோவை", "திருச்சி", "மதுரை"],
  "answer": "சென்னை"
},
{
  "question": "தமிழ்நாட்டில் வெளியிடப்பட்டுள்ள அரசியல் தகவல் இணையத்தளம் எது?",
  "options": ["www.tn.gov.in", "www.tnpsc.gov.in", "www.tneb.gov.in", "www.tn.edu.in"],
  "answer": "www.tn.gov.in"
}
              
      
];
