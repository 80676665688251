import './Operators.css'
import React from 'react';
import NavigationBar from '../../Navbar/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'; // Import the desired icon
import Footer from '../../Footer/Footer';

const Operators = () => {
    const subcategories = [
        { label: 'Set1', link: '/c/operators/1' },
        { label: 'Set2', link: '/c/operators/2' },
        { label: 'Set3', link: '/c/operators/3' },
        { label: 'Set4', link: '/c/operators/4' },
        { label: 'Set5', link: '/c/operators/5' },
        { label: 'Set6', link: '/c/operators/6' },
        { label: 'Set7', link: '/c/operators/7' },
        { label: 'Set8', link: '/c/operators/8' },
        { label: 'Set9', link: '/c/operators/9' },
        { label: 'Set10', link:'/c/operators/10' },
        // Add more subcategories as needed
      ];
    
      return (
        <div>
  <NavigationBar />

        <div className="container-fluid">
          <div className="row">
            <div className="col-9">
              <div className="row">
                {/* Map over the subcategories array to generate subcategory cards */}
                {subcategories.map((subcategory, index) => (
                  <div className="col-6 py-2" key={index}>
                    <FontAwesomeIcon icon={faFolderOpen} className="folder-icon" />
                    <a className="card-items" href={subcategory.link}>{subcategory.label}</a>
                  </div>
                ))}
              </div>
            </div>
            
          </div>
          
        </div>
            <div className="para">
                <p>
                Operators in computer programming are symbols that represent specific computations or actions to be performed on one or more operands. These operands could be variables, constants, or expressions. Operators are fundamental building blocks of any programming language and are used to perform arithmetic, logical, relational, bitwise, and assignment operations, among others.                   
                </p>
            </div>
        <Footer/>
        </div>
      );
    };
    

export default Operators;
