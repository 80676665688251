// questionsData.js

export const Variable_set9_data = 
[
    {
        "question": "What does the 'sizeof' operator return in C?",
        "options": ["Size of a variable", "Memory address of a variable", "Size of a data type", "Value of a variable"],
        "answer": "Size of a data type"
    },
    {
        "question": "Which of the following data types is not supported in C programming?",
        "options": ["float", "double", "long double", "decimal"],
        "answer": "decimal"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  printf(\"%d\", sizeof('a'));\n  return 0;\n}\n```",
        "options": ["1", "2", "4", "Compiler error"],
        "answer": "4"
    },
    {
        "question": "What is the primary purpose of the 'continue' statement in C?",
        "options": ["To end the program execution", "To skip the current iteration of a loop", "To exit from a loop or switch statement", "To return a value from a function"],
        "answer": "To skip the current iteration of a loop"
    },
    {
        "question": "Which of the following is not a valid variable name in C?",
        "options": ["_variable", "Variable1", "123variable", "var_iable"],
        "answer": "123variable"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5;\n  printf(\"%d\", ++x);\n  return 0;\n}\n```",
        "options": ["4", "5", "6", "Compiler error"],
        "answer": "6"
    },
    {
        "question": "Which header file is required to use the 'printf' function in C?",
        "options": ["<stdlib.h>", "<math.h>", "<time.h>", "<stdio.h>"],
        "answer": "<stdio.h>"
    },
    {
        "question": "What is the purpose of the 'static' keyword in C?",
        "options": ["To declare a constant variable", "To allocate memory dynamically", "To specify the scope of a variable", "To define a function"],
        "answer": "To specify the scope of a variable"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5, y = 10;\n  printf(\"%d\", x++ * ++y);\n  return 0;\n}\n```",
        "options": ["50", "60", "70", "Compiler error"],
        "answer": "60"
    },
    {
        "question": "Which of the following is not a valid type of loop in C?",
        "options": ["for", "while", "loop", "do-while"],
        "answer": "loop"
    }
];
  