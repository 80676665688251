// questionsData.js

export const Operators_set5_data = [
    {
        "question": "What is the bitwise XOR operator in C++?",
        "options": ["&", "|", "~", "^"],
        "answer": "^"
    },
    {
        "question": "Which operator is used to perform right shift in C++?",
        "options": ["<<", ">>", "*", "/"],
        "answer": ">>"
    },
    {
        "question": "What is the assignment operator used to multiply and assign in C++?",
        "options": ["=", "==", "*=", "/="],
        "answer": "*="
    },
    {
        "question": "Which operator is used to access the address of a variable in C++?",
        "options": ["*", "&", "!", "->"],
        "answer": "&"
    },
    {
        "question": "What is the bitwise complement operator in C++?",
        "options": ["&", "|", "~", "^"],
        "answer": "~"
    },
    {
        "question": "Which operator is used to perform left shift in C++?",
        "options": ["<<", ">>", "*", "/"],
        "answer": "<<"
    },
    {
        "question": "What is the assignment operator used to divide and assign in C++?",
        "options": ["=", "==", "*=", "/="],
        "answer": "/="
    },
    {
        "question": "Which operator is used to perform logical OR in C++?",
        "options": ["&&", "||", "!", "&"],
        "answer": "||"
    },
    {
        "question": "What is the size of the 'int' data type in C++?",
        "options": ["1 byte", "2 bytes", "4 bytes", "8 bytes"],
        "answer": "4 bytes"
    },
    {
        "question": "Which operator is used to access structure members in C++?",
        "options": [".", "->", "*", "&"],
        "answer": "->"
    }
];