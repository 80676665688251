// questionsData.js

export const Variable_set8_data = 
[
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 10;\n  printf(\"%d\", x * 2);\n  return 0;\n}\n```",
        "options": ["10", "20", "5", "Compiler error"],
        "answer": "20"
    },
    {
        "question": "Which of the following is not a valid way to declare a string in C?",
        "options": ["char str[] = \"Hello\";", "char *str = \"Hello\";", "char str[6] = {'H', 'e', 'l', 'l', 'o', '\\0'};", "string str = \"Hello\";"],
        "answer": "string str = \"Hello\";"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  printf(\"Hello, World!\\n\");\n  return 0;\n}\n```",
        "options": ["Hello, World!", "Hello", "World!", "Compiler error"],
        "answer": "Hello, World!\n"
    },
    {
        "question": "What is the purpose of the 'while' loop in C?",
        "options": ["To execute a block of code repeatedly", "To skip the current iteration of a loop", "To exit from a loop or switch statement", "To declare a variable"],
        "answer": "To execute a block of code repeatedly"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  char ch = 'A';\n  printf(\"%d\", ch);\n  return 0;\n}\n```",
        "options": ["A", "65", "66", "Compiler error"],
        "answer": "65"
    },
    {
        "question": "What is the purpose of the 'continue' statement in C?",
        "options": ["To end the program execution", "To skip the current iteration of a loop", "To exit from a loop or switch statement", "To return a value from a function"],
        "answer": "To skip the current iteration of a loop"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  float x = 5.25;\n  printf(\"%d\", x);\n  return 0;\n}\n```",
        "options": ["5.25", "5", "5.0", "Compiler error"],
        "answer": "Compiler error"
    },
    {
        "question": "What is the purpose of the 'do-while' loop in C?",
        "options": ["To execute a block of code repeatedly", "To skip the current iteration of a loop", "To exit from a loop or switch statement", "To declare a variable"],
        "answer": "To execute a block of code repeatedly"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  printf(\"%d\", sizeof(3.14));\n  return 0;\n}\n```",
        "options": ["4", "8", "Compiler error", "Depends on the compiler"],
        "answer": "4"
    },
    {
        "question": "What is the purpose of the 'const' keyword in C?",
        "options": ["To declare a constant variable", "To allocate memory dynamically", "To specify the scope of a variable", "To define a function"],
        "answer": "To declare a constant variable"
    }
];
  