import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBar from '../Navbar/NavigationBar';
import Footer from '../Footer/Footer';

const AboutUs = () => {
    return (
      <div>
        <NavigationBar/>
 
        <div className="about-us">
            <Container>
                <h1 className="text-center mb-5">About Us</h1>
                <Row className="mb-4">
                    <Col md={6}>
                        <h2>Our Mission</h2>
                        <p className="lead">The mission of our MCQ KIT website is to provide a comprehensive platform for users to enhance their knowledge and skills through interactive multiple-choice questions. We aim to offer diverse content covering various subjects and topics, catering to learners of all levels. Our goal is to facilitate effective learning experiences by providing insightful explanations and feedback for each question. Through continuous updates and improvements, we strive to create an engaging and user-friendly environment that fosters curiosity, critical thinking, and academic excellence. Join us on our mission to make learning accessible, enjoyable, and rewarding for everyone.</p>
                    </Col>
                    <Col md={6}>
                        <h2>Our Vision</h2>
                        <p className="lead">Our vision is to become the premier destination for individuals seeking high-quality, interactive MCQ KIT resources across diverse subjects and fields of study. We envision a platform that empowers learners worldwide to achieve their educational goals through accessible, engaging, and innovative learning experiences. By leveraging advanced technology and educational expertise, we aim to revolutionize the way people learn and acquire knowledge, ultimately fostering a culture of continuous learning and intellectual curiosity. Our vision is to make education more inclusive, effective, and enjoyable for learners of all ages and backgrounds, driving positive societal impact through lifelong learning.</p>
                    </Col>
                </Row>
             
            </Container>
        </div>
        <Footer/>
        </div>
    );
}

export default AboutUs;
