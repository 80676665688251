export const C_String_set2=[
  {
    "question": "What is the output of the following code?\n\n```c\nchar str[] = \"Hello\";\nprintf(\"%c\", str[5]);\n```",
    "options": [
      "Compilation Error",
      "Runtime Error",
      "Null Character",
      "Undefined Behavior"
    ],
    "answer": "Undefined Behavior"
  },
  {
    "question": "What function can be used to find the last occurrence of a character in a string?",
    "options": [
      "strstr()",
      "strrchr()",
      "lastchar()",
      "findlast()"
    ],
    "answer": "strrchr()"
  },
  {
    "question": "How do you convert a string to lowercase in C?",
    "options": [
      "tolower()",
      "lower()",
      "strlower()",
      "strtolower()"
    ],
    "answer": "strlower()"
  },
  {
    "question": "Which function is used to compare a specific number of characters of two strings?",
    "options": [
      "strcmp()",
      "strncmp()",
      "strncat()",
      "strcat()"
    ],
    "answer": "strncmp()"
  },
  {
    "question": "Which function is used to reverse a string in C?",
    "options": [
      "strrev()",
      "reverse()",
      "revstr()",
      "strreverse()"
    ],
    "answer": "strrev()"
  },
  {
    "question": "What does the `strncpy()` function do in C?",
    "options": [
      "Copies one string to another",
      "Concatenates two strings",
      "Copies a specific number of characters from one string to another",
      "Finds the first occurrence of a character in a string"
    ],
    "answer": "Copies a specific number of characters from one string to another"
  },
  {
    "question": "Which function is used to search for the first occurrence of a substring in a string?",
    "options": [
      "strstr()",
      "strfind()",
      "subsearch()",
      "findsub()"
    ],
    "answer": "strstr()"
  },
  {
    "question": "What is the correct way to declare a string pointer in C?",
    "options": [
      "string* str;",
      "str* string;",
      "char* str;",
      "char str[];"
    ],
    "answer": "char* str;"
  },
  {
    "question": "Which function is used to compare two strings without considering their case?",
    "options": [
      "strcmp()",
      "strcasecmp()",
      "strcompare()",
      "compare()"
    ],
    "answer": "strcasecmp()"
  },
  {
    "question": "How do you find the length of a string containing Unicode characters in C?",
    "options": [
      "strlen()",
      "wstrlen()",
      "ucharlen()",
      "mbstowcs()"
    ],
    "answer": "mbstowcs()"
  }
]
