export const C_Constant_set10=
[
    {
        "question": "What is the value of the following expression in C: 32 % 9?",
        "options": [
            "3",
            "3.55",
            "0.35",
            "5"
        ],
        "answer": "5"
    },
    {
        "question": "Which of the following is a valid way to represent a hexadecimal constant in C?",
        "options": [
            "0x2FG",
            "0xC5",
            "27H",
            "0b1010"
        ],
        "answer": "0xC5"
    },
    {
        "question": "What is the value of the following expression in C: 'I' - 'C'?",
        "options": [
            "1",
            "-1",
            "I",
            "73"
        ],
        "answer": "8"
    },
    {
        "question": "Which escape sequence is used to represent a newline character in C?",
        "options": [
            "\\n",
            "\\t",
            "\\r",
            "\\b"
        ],
        "answer": "\\n"
    },
    {
        "question": "Which of the following is NOT a valid way to represent a floating-point constant in C?",
        "options": [
            "3.14",
            "6.022e23",
            "0xFF",
            "0.1f"
        ],
        "answer": "0xFF"
    },
    {
        "question": "What is the value of the following expression in C: 21 / 4.0?",
        "options": [
            "4",
            "5.25",
            "5.0",
            "5.5"
        ],
        "answer": "5.25"
    },
    {
        "question": "Which header file is required to use the `EXIT_SUCCESS` constant in C?",
        "options": [
            "<stdio.h>",
            "<stdlib.h>",
            "<math.h>",
            "<limits.h>"
        ],
        "answer": "<stdlib.h>"
    },
    {
        "question": "Which of the following is a valid character constant in C?",
        "options": [
            "'I'",
            "'\\n'",
            "'12'",
            "'\\t'"
        ],
        "answer": "'I'"
    },
    {
        "question": "What is the value of the following expression in C: sizeof(size_t)?",
        "options": [
            "4",
            "8",
            "16",
            "Depends on the compiler"
        ],
        "answer": "Depends on the compiler"
    },
    {
        "question": "Which of the following is NOT a valid constant type in C programming?",
        "options": [
            "Integer constant",
            "Floating-point constant",
            "Character constant",
            "Dynamic constant"
        ],
        "answer": "Dynamic constant"
    }
]
