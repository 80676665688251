// questionsData.js

export const Expression_set7_data = [
      {
          "question": "What is the result of the expression 6 * (4 + 2) / 3?",
          "options": ["12", "14", "8", "10"],
          "answer": "12"
      },
      {
          "question": "Which operator is used to perform equality comparison in C?",
          "options": ["==", "!=", ">", "<"],
          "answer": "=="
      },
      {
          "question": "What will be the output of the expression 8 - 4 / 2?",
          "options": ["4", "6", "2", "3"],
          "answer": "6"
      },
      {
          "question": "Which operator is used to perform inequality comparison in C?",
          "options": ["==", "!=", ">", "<"],
          "answer": "!="
      },
      {
          "question": "What is the value of the expression (7 - 3) * (5 + 2)?",
          "options": ["35", "28", "21", "14"],
          "answer": "28"
      },
      {
          "question": "Which operator is used to perform greater than comparison in C?",
          "options": ["==", "!=", ">", "<"],
          "answer": ">"
      },
      {
          "question": "What is the result of the expression 12 / 5?",
          "options": ["2.4", "2", "3", "2.5"],
          "answer": "2"
      },
      {
          "question": "Which operator is used to perform less than comparison in C?",
          "options": ["==", "!=", ">", "<"],
          "answer": "<"
      },
      {
          "question": "What is the output of the expression 13 % 4?",
          "options": ["2", "3", "1", "0"],
          "answer": "1"
      },
      {
          "question": "Which operator is used to perform greater than or equal to comparison in C?",
          "options": [">=", "<=", "==", "!="],
          "answer": ">="
      }
  ];