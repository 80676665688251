export const DataType_set1_data = [
    {
        "question": "Which data type is used to store whole numbers in C?",
        "options": ["int", "float", "char", "double"],
        "answer": "int"
    },
    {
        "question": "What is the size of the 'int' data type in C?",
        "options": ["4 bytes", "2 bytes", "8 bytes", "Depends on the system architecture"],
        "answer": "Depends on the system architecture"
    },
    {
        "question": "Which data type is used to store single characters in C?",
        "options": ["char", "int", "float", "double"],
        "answer": "char"
    },
    {
        "question": "What is the size of the 'char' data type in C?",
        "options": ["1 byte", "2 bytes", "4 bytes", "Depends on the system architecture"],
        "answer": "1 byte"
    },
    {
        "question": "Which data type is used to store decimal numbers in C?",
        "options": ["float", "int", "char", "double"],
        "answer": "float"
    },
    {
        "question": "What is the size of the 'float' data type in C?",
        "options": ["4 bytes", "2 bytes", "8 bytes", "Depends on the system architecture"],
        "answer": "4 bytes"
    },
    {
        "question": "Which data type is used to store larger decimal numbers with higher precision in C?",
        "options": ["double", "float", "long", "int"],
        "answer": "double"
    },
    {
        "question": "What is the size of the 'double' data type in C?",
        "options": ["8 bytes", "4 bytes", "2 bytes", "Depends on the system architecture"],
        "answer": "8 bytes"
    },
    {
        "question": "Which data type is used to store Boolean values in C?",
        "options": ["bool", "boolean", "int", "char"],
        "answer": "int"
    },
    {
        "question": "What is the size of the 'bool' data type in C?",
        "options": ["1 byte", "2 bytes", "4 bytes", "Depends on the system architecture"],
        "answer": "1 byte"
    }
    
];