export const C_String_set5=[
  {
    "question": "Which function is used to extract tokens from a string based on delimiters?",
    "options": [
      "split()",
      "strtok()",
      "strsplit()",
      "substr()"
    ],
    "answer": "strtok()"
  },
  {
    "question": "How do you check if a string contains only whitespace characters in C?",
    "options": [
      "iswhite()",
      "isws()",
      "isspace()",
      "isblank()"
    ],
    "answer": "isspace()"
  },
  {
    "question": "Which function is used to extract the last token from a string based on delimiters?",
    "options": [
      "strtok()",
      "strtok_r()",
      "strlasttok()",
      "lasttoken()"
    ],
    "answer": "strtok_r()"
  },
  {
    "question": "What does the `strtol()` function do in C?",
    "options": [
      "Converts a string to a long integer",
      "Converts a string to a double",
      "Converts a string to an integer",
      "Converts a string to a float"
    ],
    "answer": "Converts a string to a long integer"
  },
  {
    "question": "How do you check if a string ends with a specific substring in C?",
    "options": [
      "strendswith()",
      "endswith()",
      "strsuffix()",
      "strendswith()"
    ],
    "answer": "strendswith()"
  },
  {
    "question": "Which function is used to replace a specific character in a string with another character?",
    "options": [
      "strreplace()",
      "strsubst()",
      "strcharreplace()",
      "replacechar()"
    ],
    "answer": "strreplace()"
  },
  {
    "question": "What is the correct way to declare and initialize a string pointer in C?",
    "options": [
      "char* str;",
      "string* str;",
      "str* string;",
      "char str[];"
    ],
    "answer": "char* str;"
  },
  {
    "question": "How do you check if a string starts with a specific substring in C?",
    "options": [
      "strprefix()",
      "startswith()",
      "strstr()",
      "strstartswith()"
    ],
    "answer": "strstartswith()"
  },
  {
    "question": "What is the correct way to access the last character of a string in C?",
    "options": [
      "str[length - 1]",
      "str[length]",
      "str[length + 1]",
      "str[last]"
    ],
    "answer": "str[length - 1]"
  },
  {
    "question": "Which function is used to convert a string to uppercase in C?",
    "options": [
      "strupcase()",
      "toupper()",
      "struppercase()",
      "strtoupper()"
    ],
    "answer": "toupper()"
  }
]

  