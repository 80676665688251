// Home.js
import React from 'react';
import { Route, Routes ,Router} from 'react-router-dom';
import Mcqmaster from '../../Main/mcqmaster';
import Variables from '../../CProgramming/Variables/Variables';
import { Variable_set1_data } from '../../CProgramming/Variables/Set1/varibalesDataset-1';
import { Variable_set2_data } from '../../CProgramming/Variables/Set2/varibales_Data_2';
import { Variable_set3_data } from '../../CProgramming/Variables/Set3/varibales_Data_3';
import { Variable_set4_data } from '../../CProgramming/Variables/Set4/varibales_Data_4';
import { Variable_set5_data } from '../../CProgramming/Variables/Set5/varibales_Data_5';
import { Variable_set6_data } from '../../CProgramming/Variables/Set6/varibales_Data_6';
import { Variable_set7_data } from '../../CProgramming/Variables/Set7/varibales_Data_7';
import { Variable_set8_data } from '../../CProgramming/Variables/Set8/varibales_Data_8';
import { Variable_set9_data } from '../../CProgramming/Variables/Set9/varibales_Data_9';
import { Variable_set10_data } from '../../CProgramming/Variables/Set10/varibales_Data_10';

const Variables_Route = () => {
  return (
   
     <Routes>
        <Route path="/variables" element={<Variables />} />
        <Route path="/variables-1" element={<Mcqmaster pageTitle="Variable MCQ Questions 1-10" questionsData={Variable_set1_data} previouslink="/c/variables-1" nextlink="/c/variables-2" />} />
        <Route path="/variables-2" element={<Mcqmaster pageTitle="Variable MCQ Questions 11-20" questionsData={Variable_set2_data} previouslink="/c/variables-1" nextlink="/c/variables-3" />} />
        <Route path="/variables-3" element={<Mcqmaster pageTitle="Variable MCQ Questions 21-30" questionsData={Variable_set3_data} previouslink="/c/variables-2" nextlink="/c/variables-4" />} />
        <Route path="/variables-4" element={<Mcqmaster pageTitle="Variable MCQ Questions 31-40" questionsData={Variable_set4_data} previouslink="/c/variables-3" nextlink="/c/variables-5" />} />
        <Route path="/variables-5" element={<Mcqmaster pageTitle="Variable MCQ Questions 41-50" questionsData={Variable_set5_data} previouslink="/c/variables-4" nextlink="/c/variables-6" />} />
        <Route path="/variables-6" element={<Mcqmaster pageTitle="Variable MCQ Questions 51-60" questionsData={Variable_set6_data} previouslink="/c/variables-5" nextlink="/c/variables-7" />} />
        <Route path="/variables-7" element={<Mcqmaster pageTitle="Variable MCQ Questions 61-70" questionsData={Variable_set7_data} previouslink="/c/variables-6" nextlink="/c/variables-8" />} />
        <Route path="/variables-8" element={<Mcqmaster pageTitle="Variable MCQ Questions 71-80" questionsData={Variable_set8_data} previouslink="/c/variables-7" nextlink="/c/variables-9" />} />
        <Route path="/variables-9" element={<Mcqmaster pageTitle="Variable MCQ Questions 81-90" questionsData={Variable_set9_data} previouslink="/c/variables-8" nextlink="/c/variables-10" />} />
        <Route path="/variables-10" element={<Mcqmaster pageTitle="Variable MCQ Questions 91-100" questionsData={Variable_set10_data} previouslink="/c/variables-9" nextlink="/c/variables-1" />} />
       
      </Routes>

   
  );
}

export default Variables_Route;
