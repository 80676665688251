import React from 'react';
import NavigationBar from '../../Navbar/NavigationBar';
import './TNPSCIVQuizCategory.css'; // Make sure to import your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'; // Import the desired icon
import Footer from '../../Footer/Footer';

const TNPSCIVQuizCategory = () => {
    const subcategories = [
        { label: 'General Science', link: '/Tnpsc/IV/gs' },
        { label: 'Current Events', link: '#' },
        { label: 'Geography', link: '#' },
        { label: 'History', link: '#' },
        // Add more subcategories as needed
      ];
    
      return (
        <div>
  <NavigationBar />
 

        <div className="container-fluid">
          <div className="row">
            <div className="col-9">
              <div className="row">
                {/* Map over the subcategories array to generate subcategory cards */}
                {subcategories.map((subcategory, index) => (
                  <div className="col-6 py-2" key={index}>
                    <FontAwesomeIcon icon={faFolderOpen} className="folder-icon" />
                    <a className="card-items" href={subcategory.link}>{subcategory.label}</a>
                  </div>
                ))}
              </div>
            </div>
            
          </div>
          
        </div>
            <div className="col-">
<p></p>
            </div>
        <Footer/>
        </div>
      );
    };
    

export default TNPSCIVQuizCategory;
