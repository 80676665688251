export const categories = [
  {
    title: "Variables",
    subcategories: [
      { "name": "set1", "link": "/variables-1" },
      { "name": "set2", "link": "/variables-2" },
      { "name": "set3", "link": "/variables-3" },
      { "name": "set4", "link": "/variables-4" },
      { "name": "set5", "link": "/variables-5" },
      { "name": "set6", "link": "/variables-6" },
      { "name": "set7", "link": "/variables-7" },
      { "name": "set8", "link": "/variables-8" },
      { "name": "set9", "link": "/variables-9" },
      { "name": "set10", "link": "/variables-10" }
    ]
},
{
    title: "Data Types",
    subcategories: [
        { "name": "set1", "link": "/data-types-1" },
        { "name": "set2", "link": "/data-types-2" }
    ]
},
{
    title: "Operators",
    subcategories: [
        { "name": "set1", "link": "/operators-1" },
        { "name": "set2", "link": "/operators-2" }
    ]
},
{
    title: "Expressions",
    subcategories: [
        { "name": "set1", "link": "/expressions-1" },
        { "name": "set2", "link": "/expressions-2" }
    ]
},
{
    title: "Control Structures",
    subcategories: [
        { "name": "set1", "link": "/control-structures-1" },
        { "name": "set2", "link": "/control-structures-2" }
    ]
},
{
    title: "Functions",
    subcategories: [
        { "name": "set1", "link": "/functions-1" },
        { "name": "set2", "link": "/functions-2" }
    ]
},
];