// questionsData.js

export const Expression_set5_data = [
      {
          "question": "What is the result of the expression 3 * (4 + 2) / 2?",
          "options": ["9", "12", "6", "7"],
          "answer": "9"
      },
      {
          "question": "Which operator is used to perform logical XOR in C?",
          "options": ["^", "&&", "||", "!"],
          "answer": "^"
      },
      {
          "question": "What will be the output of the expression 7 - 4 / 2?",
          "options": ["5", "2", "3", "6"],
          "answer": "5"
      },
      {
          "question": "Which operator is used to perform bitwise XOR in C?",
          "options": ["^", "&", "|", "~"],
          "answer": "^"
      },
      {
          "question": "What is the value of the expression (6 - 3) * (4 + 2)?",
          "options": ["12", "18", "24", "36"],
          "answer": "18"
      },
      {
          "question": "Which operator is used to perform bitwise left shift in C?",
          "options": ["<<", ">>", "&", "|"],
          "answer": "<<"
      },
      {
          "question": "What is the result of the expression 8 / 3?",
          "options": ["2.6667", "3", "2", "2.5"],
          "answer": "2"
      },
      {
          "question": "Which operator is used to perform bitwise right shift in C?",
          "options": ["<<", ">>", "&", "|"],
          "answer": ">>"
      },
      {
          "question": "What is the output of the expression 12 % 5?",
          "options": ["2", "3", "1", "4"],
          "answer": "2"
      },
      {
          "question": "Which operator is used to perform bitwise NOT in C?",
          "options": ["^", "&", "|", "~"],
          "answer": "~"
      }
  ];  