export const C_Constant_set2=
[
    {
        "question": "What is the value of the following expression in C: 15 % 4?",
        "options": [
            "3",
            "3.75",
            "4",
            "3.5"
        ],
        "answer": "3"
    },
    {
        "question": "Which of the following is a valid way to represent a binary constant in C?",
        "options": [
            "0xFF",
            "0b1010",
            "27H",
            "037"
        ],
        "answer": "0b1010"
    },
    {
        "question": "What is the value of the following expression in C: 'A' + 1?",
        "options": [
            "B",
            "66",
            "67",
            "2"
        ],
        "answer": "B"
    },
    {
        "question": "Which escape sequence is used to represent a backslash character in C?",
        "options": [
            "\\n",
            "\\t",
            "\\\\",
            "\\r"
        ],
        "answer": "\\\\"
    },
    {
        "question": "Which of the following is NOT a valid way to represent an octal constant in C?",
        "options": [
            "092",
            "037",
            "0xFF",
            "0o777"
        ],
        "answer": "0xFF"
    },
    {
        "question": "What is the value of the following expression in C: 10.5 * 2?",
        "options": [
            "21",
            "20.0",
            "21.0",
            "20"
        ],
        "answer": "21.0"
    },
    {
        "question": "Which header file is required to use the `NULL` pointer constant in C?",
        "options": [
            "<stdio.h>",
            "<stdlib.h>",
            "<math.h>",
            "<stddef.h>"
        ],
        "answer": "<stddef.h>"
    },
    {
        "question": "Which of the following is a valid floating-point constant in C?",
        "options": [
            "6.022e23",
            "0o777",
            "0xFF",
            "0.1f"
        ],
        "answer": "0.1f"
    },
    {
        "question": "What is the value of the following expression in C: sizeof(double)?",
        "options": [
            "4",
            "8",
            "2",
            "Depends on the compiler"
        ],
        "answer": "8"
    },
    {
        "question": "Which of the following is NOT a valid constant type in C programming?",
        "options": [
            "Integer constant",
            "Floating-point constant",
            "Character constant",
            "Dynamic constant"
        ],
        "answer": "Dynamic constant"
    }
]
