// questionsData.js

export const Expression_set10_data = [
      {
          "question": "What is the result of the expression 5 * (3 + 2) / 4?",
          "options": ["5", "6", "7", "8"],
          "answer": "6"
      },
      {
          "question": "Which operator is used to perform logical XOR in C?",
          "options": ["^", "&&", "||", "!"],
          "answer": "^"
      },
      {
          "question": "What will be the output of the expression 9 - 4 * 2?",
          "options": ["1", "5", "10", "3"],
          "answer": "1"
      },
      {
          "question": "Which operator is used to perform bitwise XOR in C?",
          "options": ["^", "&", "|", "~"],
          "answer": "^"
      },
      {
          "question": "What is the value of the expression (8 - 2) * (5 + 3)?",
          "options": ["64", "48", "56", "40"],
          "answer": "48"
      },
      {
          "question": "Which operator is used to perform bitwise left shift in C?",
          "options": ["<<", ">>", "&", "|"],
          "answer": "<<"
      },
      {
          "question": "What is the result of the expression 14 / 3?",
          "options": ["4.5", "4", "5", "3"],
          "answer": "4"
      },
      {
          "question": "Which operator is used to perform bitwise right shift in C?",
          "options": ["<<", ">>", "&", "|"],
          "answer": ">>"
      },
      {
          "question": "What is the output of the expression 11 % 4?",
          "options": ["2", "3", "1", "0"],
          "answer": "3"
      },
      {
          "question": "Which operator is used to perform bitwise NOT in C?",
          "options": ["^", "&", "|", "~"],
          "answer": "~"
      }
  ];  
  