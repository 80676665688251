// Data_Type_Route.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Mcqmaster from '../../Main/mcqmaster';
import C_Constant from '../../CProgramming/Constants/C_Constant';
import { C_Constant_set1 } from '../../CProgramming/Constants/Data/C_Constant_Set1';
import { C_Constant_set2 } from '../../CProgramming/Constants/Data/C_Constant_Set2';
import { C_Constant_set3 } from '../../CProgramming/Constants/Data/C_Constant_Set3';
import { C_Constant_set4 } from '../../CProgramming/Constants/Data/C_Constant_Set4';
import { C_Constant_set5 } from '../../CProgramming/Constants/Data/C_Constant_Set5';
import { C_Constant_set6 } from '../../CProgramming/Constants/Data/C_Constant_Set6';
import { C_Constant_set7 } from '../../CProgramming/Constants/Data/C_Constant_Set7';
import { C_Constant_set8 } from '../../CProgramming/Constants/Data/C_Constant_Set8';
import { C_Constant_set9 } from '../../CProgramming/Constants/Data/C_Constant_Set9';
import { C_Constant_set10 } from '../../CProgramming/Constants/Data/C_Constant_Set10';



const C_Constant_Route = () => {
  return (
    <Routes>
      <Route path="" element={<C_Constant />} />
      <Route path="1" element={<Mcqmaster pageTitle="Constant MCQ Questions 1-10" questionsData={C_Constant_set1} previouslink="/c/constant/1" nextlink="/c/constant/2" />} />
      <Route path="2" element={<Mcqmaster pageTitle="Constant MCQ Questions 11-20" questionsData={C_Constant_set2} previouslink="/c/constant/1" nextlink="/c/constant/3" />} />
      <Route path="3" element={<Mcqmaster pageTitle="Constant MCQ Questions 21-30" questionsData={C_Constant_set3} previouslink="/c/constant/2" nextlink="/c/constant/4" />} />
      <Route path="4" element={<Mcqmaster pageTitle="Constant MCQ Questions 31-40" questionsData={C_Constant_set4} previouslink="/c/constant/3" nextlink="/c/constant/5" />} />

      <Route path="5" element={<Mcqmaster pageTitle="Constant MCQ Questions 41-50" questionsData={C_Constant_set5} previouslink="/c/constant/4" nextlink="/c/constant/6" />} />
      <Route path="6" element={<Mcqmaster pageTitle="Constant MCQ Questions 51-60" questionsData={C_Constant_set6} previouslink="/c/constant/5" nextlink="/c/constant/7" />} />
      <Route path="7" element={<Mcqmaster pageTitle="Constant MCQ Questions 61-70" questionsData={C_Constant_set7} previouslink="/c/constant/6" nextlink="/c/constant/8" />} />
      <Route path="8" element={<Mcqmaster pageTitle="Constant MCQ Questions 71-80" questionsData={C_Constant_set8} previouslink="/c/constant/7" nextlink="/c/constant/9" />} />

      <Route path="9" element={<Mcqmaster pageTitle="Constant MCQ Questions 81-90" questionsData={C_Constant_set9} previouslink="/c/constant/8" nextlink="/c/constant/10" />} />
      <Route path="10" element={<Mcqmaster pageTitle="Constant MCQ Questions 91-100" questionsData={C_Constant_set10} previouslink="/c/constant/9" nextlink="/c/constant/1" />} />
  
    </Routes>
  );
}

export default C_Constant_Route;
