import React from 'react';
import { Link } from 'react-router-dom';
import './NotFoundPage.css'; // Import your CSS file for styling
import NotFoundSVG from '../Asserts/404.svg'; // Import your SVG file
import NavigationBar from '../Navbar/NavigationBar';
import Footer from '../Footer/Footer';

const NotFound = () => {
  return (
    <div>
     <NavigationBar/>
    <div className="cont-404">
      <img src={NotFoundSVG} alt="404" />
      <br/>
      <Link to="/">Back to Home</Link>
    </div>
          <Footer/>
    </div>
  );
};

export default NotFound;
