// questionsData.js

export const Expression_set6_data = [
      {
          "question": "What is the result of the expression 4 * (5 + 3) / 2?",
          "options": ["16", "14", "12", "10"],
          "answer": "16"
      },
      {
          "question": "Which operator is used to perform logical AND in C?",
          "options": ["&&", "||", "&", "!"],
          "answer": "&&"
      },
      {
          "question": "What will be the output of the expression 10 - 3 * 2?",
          "options": ["4", "14", "8", "12"],
          "answer": "4"
      },
      {
          "question": "Which operator is used to perform logical OR in C?",
          "options": ["&&", "||", "&", "!"],
          "answer": "||"
      },
      {
          "question": "What is the value of the expression (9 - 2) * (6 + 3)?",
          "options": ["77", "63", "54", "45"],
          "answer": "63"
      },
      {
          "question": "Which operator is used to perform bitwise AND in C?",
          "options": ["&&", "&", "||", "!"],
          "answer": "&"
      },
      {
          "question": "What is the result of the expression 15 / 4?",
          "options": ["3.75", "4", "3", "3.5"],
          "answer": "3"
      },
      {
          "question": "Which operator is used to perform bitwise OR in C?",
          "options": ["&&", "&", "||", "!"],
          "answer": "|"
      },
      {
          "question": "What is the output of the expression 17 % 5?",
          "options": ["2", "3", "1", "4"],
          "answer": "2"
      },
      {
          "question": "Which operator is used to perform bitwise NOT in C?",
          "options": ["&&", "&", "||", "~"],
          "answer": "~"
      }
  ]
  