// questionsData.js

export const Expression_set1_data = [
       {
        "question": "What is the arithmetic operator used for addition in C?",
        "options": ["+", "-", "", "/"],
        "answer": "+"
        },
        {
        "question": "What is the result of the expression 5 * 3 + 4 / 2?",
        "options": ["11", "15", "10", "17"],
        "answer": "11"
        },
        {
        "question": "What is the value of the expression 3 * (2 + 5) - 4?",
        "options": ["15", "23", "19", "11"],
        "answer": "19"
        },
        {
        "question": "Which operator is used for exponentiation in C?",
        "options": ["^", "", "", "^"],
        "answer": "**"
        },
        {
        "question": "What is the result of the expression 10 / 3 in C?",
        "options": ["3.3333", "3", "3.0", "4"],
        "answer": "3"
        },
        {
        "question": "Which of the following is the correct operator to perform modulus division in C?",
        "options": ["%", "//", "mod", "\\"],
        "answer": "%"
        },
        {
        "question": "What is the result of the expression 10 % 3 in C?",
        "options": ["1", "2", "3", "0"],
        "answer": "1"
        },
        {
        "question": "Which operator is used to increment a variable by 1 in C?",
        "options": ["++", "--", "+=", "-="],
        "answer": "++"
        },
        {
        "question": "What is the result of the expression 7 - 4 * 2?",
        "options": ["1", "6", "-1", "8"],
        "answer": "-1"
        },
        {
        "question": "What is the value of the expression (15 - 6) / 3?",
        "options": ["3", "2", "5", "6"],
        "answer": "3"
        }
    
  ];
  