export const C_If_Else_set9=[
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 20;\n    if (x >= 20)\n        printf(\"A\");\n    else if (x > 10)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "A"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = -15;\n    if (x < 0)\n        printf(\"Negative\");\n    else if (x > 0)\n        printf(\"Positive\");\n    else\n        printf(\"Zero\");\n    return 0;\n}\n",
    "options": [
      "Negative",
      "Positive",
      "Zero",
      "Compilation Error"
    ],
    "answer": "Negative"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 5;\n    if (x < 0)\n        printf(\"Negative\");\n    else if (x == 0)\n        printf(\"Zero\");\n    else\n        printf(\"Positive\");\n    return 0;\n}\n",
    "options": [
      "Negative",
      "Positive",
      "Zero",
      "Compilation Error"
    ],
    "answer": "Positive"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 0;\n    if (x == 0)\n        printf(\"A\");\n    else if (x > 0)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "A"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = -5;\n    if (x < 0)\n        printf(\"Negative\");\n    else if (x == 0)\n        printf(\"Zero\");\n    else\n        printf(\"Positive\");\n    return 0;\n}\n",
    "options": [
      "Negative",
      "Positive",
      "Zero",
      "Compilation Error"
    ],
    "answer": "Negative"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 10;\n    if (x < 5)\n        printf(\"Less than 5\");\n    else if (x > 5)\n        printf(\"Greater than 5\");\n    else\n        printf(\"Equal to 5\");\n    return 0;\n}\n",
    "options": [
      "Less than 5",
      "Greater than 5",
      "Equal to 5",
      "Compilation Error"
    ],
    "answer": "Greater than 5"
  },
  {
    "question": "What will be the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 0;\n    if (x)\n        printf(\"Non-zero\");\n    else\n        printf(\"Zero\");\n    return 0;\n}\n",
    "options": [
      "Non-zero",
      "Zero",
      "Non-zeroZero",
      "Compilation Error"
    ],
    "answer": "Zero"
  },
  {
    "question": "What is the output of the following C code?\n\n#include <stdio.h>\n\nint main() {\n    int x = 15;\n    if (x >= 20)\n        printf(\"A\");\n    else if (x >= 10)\n        printf(\"B\");\n    else\n        printf(\"C\");\n    return 0;\n}\n",
    "options": [
      "A",
      "B",
      "C",
      "Compilation Error"
    ],
    "answer": "B"
  }]