export const C_String_set9=[
  {
    "question": "Which function is used to copy one string to another in C?",
    "options": [
      "strcpy()",
      "copystr()",
      "strncpy()",
      "copy()"
    ],
    "answer": "strcpy()"
  },
  {
    "question": "What is the purpose of the `strlen()` function in C?",
    "options": [
      "Converts a string to lowercase",
      "Finds the length of a string",
      "Compares two strings",
      "Copies a string to another"
    ],
    "answer": "Finds the length of a string"
  },
  {
    "question": "How do you find the first occurrence of a substring in a string in C?",
    "options": [
      "strstr()",
      "strpos()",
      "strfind()",
      "subfind()"
    ],
    "answer": "strstr()"
  },
  {
    "question": "What does the `strcat()` function do in C?",
    "options": [
      "Compares two strings",
      "Concatenates two strings",
      "Copies a string to another",
      "Finds the first occurrence of a character in a string"
    ],
    "answer": "Concatenates two strings"
  },
  {
    "question": "Which function is used to compare two strings in C?",
    "options": [
      "comparestr()",
      "strcmp()",
      "strcompare()",
      "strcoll()"
    ],
    "answer": "strcmp()"
  },
  {
    "question": "How do you check if a string is empty in C?",
    "options": [
      "strlen(str) == 0",
      "str == \"\"",
      "strcmp(str, \"\") == 0",
      "strempty(str)"
    ],
    "answer": "strlen(str) == 0"
  },
  {
    "question": "What is the correct way to convert a string to lowercase in C?",
    "options": [
      "tolower()",
      "lower()",
      "strlower()",
      "strtolower()"
    ],
    "answer": "strlower()"
  },
  {
    "question": "What is the correct way to compare two strings in C without considering their case?",
    "options": [
      "strcaseeq()",
      "strcasecmp()",
      "strcomp()",
      "strcmpnocase()"
    ],
    "answer": "strcasecmp()"
  },
  {
    "question": "Which function is used to find the first occurrence of a character in a string after a specified position?",
    "options": [
      "strchr()",
      "strnchr()",
      "strstr()",
      "strchrpos()"
    ],
    "answer": "strnchr()"
  },
  {
    "question": "How do you find the last occurrence of a character in a string in C?",
    "options": [
      "strrchr()",
      "lastchar()",
      "strlast()",
      "findlastchar()"
    ],
    "answer": "strrchr()"
  }
]
