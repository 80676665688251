export const C_String_set10=[
  {
    "question": "What is the correct way to initialize an empty string in C?",
    "options": [
      "char* str = \"\";",
      "char str[] = \"\";",
      "char str[0];",
      "string str;"
    ],
    "answer": "char str[] = \"\";"
  },
  {
    "question": "Which function is used to compare a specific number of characters of two strings?",
    "options": [
      "strcmp()",
      "strncmp()",
      "strncat()",
      "strcat()"
    ],
    "answer": "strncmp()"
  },
  {
    "question": "What is the correct way to access the first character of a string in C?",
    "options": [
      "str[0]",
      "str[1]",
      "str[2]",
      "str[1]"
    ],
    "answer": "str[0]"
  },
  {
    "question": "How do you check if a string contains only alphabetic characters in C?",
    "options": [
      "isalpha()",
      "isalnum()",
      "isalphastr()",
      "strisalpha()"
    ],
    "answer": "strisalpha()"
  },
  {
    "question": "Which function is used to find the last occurrence of a substring in a string?",
    "options": [
      "strstrlast()",
      "strrstr()",
      "strfindlast()",
      "findlaststr()"
    ],
    "answer": "strrstr()"
  },
  {
    "question": "What does the `strtok()` function do in C?",
    "options": [
      "Compares two strings",
      "Concatenates two strings",
      "Tokenizes a string based on delimiters",
      "Copies a string to another"
    ],
    "answer": "Tokenizes a string based on delimiters"
  },
  {
    "question": "How do you compare two strings in C, ignoring case?",
    "options": [
      "strcmpnocase()",
      "strcaseeq()",
      "strcasecmp()",
      "strcomp()"
    ],
    "answer": "strcasecmp()"
  },
  {
    "question": "Which function is used to convert a string to an integer in C?",
    "options": [
      "atoi()",
      "strtoi()",
      "intstr()",
      "strtoint()"
    ],
    "answer": "atoi()"
  },
  {
    "question": "What is the correct way to access the last character of a string in C?",
    "options": [
      "str[length - 1]",
      "str[length]",
      "str[length + 1]",
      "str[last]"
    ],
    "answer": "str[length - 1]"
  }
]
