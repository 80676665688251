import './GS.css'
import React from 'react';
import NavigationBar from '../../../Navbar/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'; // Import the desired icon
import Footer from '../../../Footer/Footer';
const TNPSCIVGS = () => {
    const subcategories = [
        { label: 'Set1', link: '/Tnpsc/IV/gs/s1' },
        { label: 'Set2', link: '/Tnpsc/IV/gs/s2' },
        { label: 'Set3', link: '/Tnpsc/IV/gs/s3' },
        { label: 'Set4', link: '/Tnpsc/IV/gs/s4' },
        { label: 'Set5', link: '/Tnpsc/IV/gs/s5' },
        { label: 'Set6', link: '/Tnpsc/IV/gs/s6' },
        { label: 'Set7', link: '/Tnpsc/IV/gs/s7' },
        { label: 'Set8', link: '/Tnpsc/IV/gs/s8' },
 
        // Add more subcategories as needed
      ];
    
      return (
        <div>
  <NavigationBar />

        <div className="container-fluid">
          <div className="row">
            <div className="col-9">
              <div className="row">
                {/* Map over the subcategories array to generate subcategory cards */}
                {subcategories.map((subcategory, index) => (
                  <div className="col-6 py-2" key={index}>
                    <FontAwesomeIcon icon={faFolderOpen} className="folder-icon" />
                    <a className="card-items" href={subcategory.link}>{subcategory.label}</a>
                  </div>
                ))}
              </div>
            </div>
            
          </div>
          
        </div>
            <div className="col-">
              <p></p>
            </div>
        <Footer/>
        </div>
      );
    };
    

export default TNPSCIVGS;
