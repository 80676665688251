// Data_Type_Route.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Mcqmaster from '../../Main/mcqmaster';
import C_String from '../../CProgramming/String/C_String';
import { C_String_set1 } from '../../CProgramming/String/Data/C_String_set1';
import { C_String_set2 } from '../../CProgramming/String/Data/C_String_set2';
import { C_String_set3 } from '../../CProgramming/String/Data/C_String_set3';
import { C_String_set4 } from '../../CProgramming/String/Data/C_String_set4';
import { C_String_set5 } from '../../CProgramming/String/Data/C_String_set5';
import { C_String_set6 } from '../../CProgramming/String/Data/C_String_set6';
import { C_String_set7 } from '../../CProgramming/String/Data/C_String_set7';
import { C_String_set8 } from '../../CProgramming/String/Data/C_String_set8';
import { C_String_set9 } from '../../CProgramming/String/Data/C_String_set9';
import { C_String_set10 } from '../../CProgramming/String/Data/C_String_set10';


const C_String_Route = () => {
  return (
    <Routes>
      <Route path="" element={<C_String />} />
      <Route path="1" element={<Mcqmaster pageTitle="String MCQ Questions 1-10" questionsData={C_String_set1} previouslink="/c/string/1" nextlink="/c/string/2" />} />
      <Route path="2" element={<Mcqmaster pageTitle="String MCQ Questions 11-20" questionsData={C_String_set2} previouslink="/c/string/1" nextlink="/c/string/3" />} />
      <Route path="3" element={<Mcqmaster pageTitle="String MCQ Questions 21-30" questionsData={C_String_set3} previouslink="/c/string/2" nextlink="/c/string/4" />} />
      <Route path="4" element={<Mcqmaster pageTitle="String MCQ Questions 31-40" questionsData={C_String_set4} previouslink="/c/string/3" nextlink="/c/string/5" />} />

      <Route path="5" element={<Mcqmaster pageTitle="String MCQ Questions 41-50" questionsData={C_String_set5} previouslink="/c/string/4" nextlink="/c/string/6" />} />
      <Route path="6" element={<Mcqmaster pageTitle="String MCQ Questions 51-60" questionsData={C_String_set6} previouslink="/c/string/5" nextlink="/c/string/7" />} />
      <Route path="7" element={<Mcqmaster pageTitle="String MCQ Questions 61-70" questionsData={C_String_set7} previouslink="/c/string/6" nextlink="/c/string/8" />} />
      <Route path="8" element={<Mcqmaster pageTitle="String MCQ Questions 71-80" questionsData={C_String_set8} previouslink="/c/string/7" nextlink="/c/string/9" />} />

      <Route path="9" element={<Mcqmaster pageTitle="String MCQ Questions 81-90" questionsData={C_String_set9} previouslink="/c/string/8" nextlink="/c/string/10" />} />
      <Route path="10" element={<Mcqmaster pageTitle="String MCQ Questions 91-100" questionsData={C_String_set10} previouslink="/c/string/9" nextlink="/c/string/1" />} />
  
    </Routes>
  );
}

export default C_String_Route;
