// Home.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Mcqmaster from '../../Main/mcqmaster';
import Expressions from '../../CProgramming/Expressions/Expressions';
import { Expression_set1_data } from '../../CProgramming/Expressions/Data/Expression_data_1';
import { Expression_set2_data } from '../../CProgramming/Expressions/Data/Expression_data_2';
import { Expression_set3_data } from '../../CProgramming/Expressions/Data/Expression_data_3';
import { Expression_set4_data } from '../../CProgramming/Expressions/Data/Expression_data_4';
import { Expression_set5_data } from '../../CProgramming/Expressions/Data/Expression_data_5';
import { Expression_set6_data } from '../../CProgramming/Expressions/Data/Expression_data_6';
import { Expression_set7_data } from '../../CProgramming/Expressions/Data/Expression_data_7';
import { Expression_set8_data } from '../../CProgramming/Expressions/Data/Expression_data_8';
import { Expression_set9_data } from '../../CProgramming/Expressions/Data/Expression_data_9';
import { Expression_set10_data } from '../../CProgramming/Expressions/Data/Expression_data_10';
const Expressions_Route = () => {
  return (
   
     <Routes>
        <Route path="/" element={<Expressions />} />
        <Route path="/1" element={<Mcqmaster pageTitle="Expressions MCQ Questions 1-10" questionsData={Expression_set1_data} previouslink="/c/expressions/10" nextlink="/c/expressions/2" />} />
        <Route path="/2" element={<Mcqmaster pageTitle="Expressions MCQ Questions 11-20" questionsData={Expression_set2_data} previouslink="/c/expressions/1" nextlink="/c/expressions/3" />} />
        <Route path="/3" element={<Mcqmaster pageTitle="Expressions MCQ Questions 21-30" questionsData={Expression_set3_data} previouslink="/c/expressions/2" nextlink="/c/expressions/4" />} />
        <Route path="/4" element={<Mcqmaster pageTitle="Expressions MCQ Questions 31-40" questionsData={Expression_set4_data} previouslink="/c/expressions/3" nextlink="/c/expressions/5" />} />
        <Route path="/5" element={<Mcqmaster pageTitle="Expressions MCQ Questions 41-50" questionsData={Expression_set5_data} previouslink="/c/expressions/4" nextlink="/c/expressions/6" />} />
        <Route path="/6" element={<Mcqmaster pageTitle="Expressions MCQ Questions 51-60" questionsData={Expression_set6_data} previouslink="/c/expressions/5" nextlink="/c/expressions/7" />} />
        <Route path="/7" element={<Mcqmaster pageTitle="Expressions MCQ Questions 61-70" questionsData={Expression_set7_data} previouslink="/c/expressions/6" nextlink="/c/expressions/8" />} />
        <Route path="/8" element={<Mcqmaster pageTitle="Expressions MCQ Questions 71-80" questionsData={Expression_set8_data} previouslink="/c/expressions/7" nextlink="/c/expressions/9" />} />
        <Route path="/9" element={<Mcqmaster pageTitle="Expressions MCQ Questions 81-90" questionsData={Expression_set9_data} previouslink="/c/expressions/8" nextlink="/c/expressions/10" />} />
        <Route path="/10" element={<Mcqmaster pageTitle="Expressions MCQ Questions 91-100" questionsData={Expression_set10_data} previouslink="/c/expressions/9" nextlink="/c/expressions/1" />} />

      </Routes>

   
  );
}

export default Expressions_Route;
