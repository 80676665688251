// questionsData.js

export const Variable_set6_data = 
[
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 10, y = 3;\n  printf(\"%d\", x % y);\n  return 0;\n}\n```",
        "options": ["1", "2", "3", "Compiler error"],
        "answer": "1"
    },
    {
        "question": "Which of the following is not a valid data type in C?",
        "options": ["int", "char", "float", "string"],
        "answer": "string"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5;\n  printf(\"%d\", x + 2 * 3);\n  return 0;\n}\n```",
        "options": ["10", "11", "15", "Compiler error"],
        "answer": "11"
    },
    {
        "question": "What is the purpose of the 'break' statement in C?",
        "options": ["To end the program execution", "To skip the current iteration of a loop", "To exit from a loop or switch statement", "To return a value from a function"],
        "answer": "To exit from a loop or switch statement"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  printf(\"%c\", 65);\n  return 0;\n}\n```",
        "options": ["65", "A", "Compiler error", "NULL"],
        "answer": "A"
    },
    {
        "question": "What is the purpose of the 'goto' statement in C?",
        "options": ["To jump to a specified label", "To declare a variable", "To define a function", "To include a header file"],
        "answer": "To jump to a specified label"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5;\n  x += 3;\n  printf(\"%d\", x);\n  return 0;\n}\n```",
        "options": ["5", "8", "3", "Compiler error"],
        "answer": "8"
    },
    {
        "question": "What is the purpose of the 'continue' statement in C?",
        "options": ["To end the program execution", "To skip the current iteration of a loop", "To exit from a loop or switch statement", "To return a value from a function"],
        "answer": "To skip the current iteration of a loop"
    },
    {
        "question": "What is the output of the following code snippet?\n\n```c\n#include <stdio.h>\nint main() {\n  int x = 5;\n  printf(\"%d\", ++x);\n  return 0;\n}\n```",
        "options": ["4", "5", "6", "Compiler error"],
        "answer": "6"
    },
    {
        "question": "What is the purpose of the 'extern' keyword in C?",
        "options": ["To declare a global variable", "To allocate memory dynamically", "To specify the scope of a variable", "To define a function"],
        "answer": "To declare a global variable"
    }
];
  