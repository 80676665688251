export const DataType_set2_data = [
    
        {
            "question": "Which data type is used to store larger whole numbers in C?",
            "options": ["int", "float", "char", "long"],
            "answer": "long"
        },
        {
            "question": "What is the size of the 'long' data type in C?",
            "options": ["4 bytes", "2 bytes", "8 bytes", "Depends on the system architecture"],
            "answer": "Depends on the system architecture"
        },
        {
            "question": "Which data type is used to store positive whole numbers only in C?",
            "options": ["int", "float", "char", "unsigned int"],
            "answer": "unsigned int"
        },
        {
            "question": "What is the size of the 'unsigned int' data type in C?",
            "options": ["4 bytes", "2 bytes", "8 bytes", "Depends on the system architecture"],
            "answer": "Depends on the system architecture"
        },
        {
            "question": "Which data type is used to store memory addresses in C?",
            "options": ["int", "float", "char", "pointer"],
            "answer": "pointer"
        },
        {
            "question": "What is the size of a pointer data type in C?",
            "options": ["4 bytes", "8 bytes", "2 bytes", "Depends on the system architecture"],
            "answer": "Depends on the system architecture"
        },
        {
            "question": "Which data type is used to represent absence of type in C?",
            "options": ["int", "float", "char", "void"],
            "answer": "void"
        },
        {
            "question": "Which data type is used to store enumeration constants in C?",
            "options": ["int", "float", "char", "enum"],
            "answer": "enum"
        },
        {
            "question": "What is the size of the 'enum' data type in C?",
            "options": ["4 bytes", "2 bytes", "8 bytes", "Depends on the system architecture"],
            "answer": "Depends on the system architecture"
        },
        {
            "question": "Which data type is used to store a group of elements with the same data type in C?",
            "options": ["int", "float", "array", "char"],
            "answer": "array"
        }
    
    
    
];