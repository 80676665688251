import React from 'react';
import NavigationBar from '../Navbar/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCode, faCogs, faBolt, faLaptopCode, faAtom, faSatellite, faIndustry, faRecycle } from '@fortawesome/free-solid-svg-icons'; 
import './QuizCategory.css';
import Footer from '../Footer/Footer';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
const QuizCategory = () => {

  const engineeringItems = [
    { label: 'Civil Engineering', link: '/#' },
    { label: 'Electrical Engineering', link: '/#' },
    { label: 'Mechanical Engineering', link: '/#' },
    { label: 'Electronics and Comm. Engineering', link: '/#' }
  ];

  const programmingLanguageItems = [
    { label: 'C', link: '/cprogramming' },
    { label: 'C++', link: '/cplusplus' },
    { label: 'Java', link: '/java' },
    { label: 'Python', link: '/python' },
    { label: 'JavaScript', link: '/javascript' },
    { label: 'Ruby', link: '/ruby' },
    { label: 'PHP', link: '/php' },
    { label: 'Swift', link: '/swift' },
    { label: 'Kotlin', link: '/kotlin' },
    { label: 'Go', link: '/go' },
    { label: 'Rust', link: '/rust' },
    { label: 'TypeScript', link: '/typescript' },
    { label: 'Scala', link: '/scala' },
    { label: 'Dart', link: '/dart' },
    { label: 'R', link: '/r' },
    { label: 'Perl', link: '/perl' },
    { label: 'Objective-C', link: '/objectivec' },
    { label: 'Shell', link: '/shell' },
    { label: 'Haskell', link: '/haskell' },
    { label: 'Elixir', link: '/elixir' },
    { label: 'Groovy', link: '/groovy' },
    { label: 'Lua', link: '/lua' },
  ];
  
  const civilEngineeringItems = [
    { label: 'Structural Engineering', link: '/structural-engineering' },
    { label: 'Geotechnical Engineering', link: '/geotechnical-engineering' },
    { label: 'Transportation Engineering', link: '/transportation-engineering' },
    { label: 'Environmental Engineering', link: '/environmental-engineering' },
    { label: 'Water Resources Engineering', link: '/water-resources-engineering' },
    { label: 'Construction Engineering', link: '/construction-engineering' },
    { label: 'Urban Engineering', link: '/urban-engineering' },
    { label: 'Coastal Engineering', link: '/coastal-engineering' },
    { label: 'Materials Engineering', link: '/materials-engineering' },
    { label: 'Surveying Engineering', link: '/surveying-engineering' },
    { label: 'Earthquake Engineering', link: '/earthquake-engineering' },
    { label: 'Pavement Engineering', link: '/pavement-engineering' },
    { label: 'Hydraulic Engineering', link: '/hydraulic-engineering' },
    { label: 'Railway Engineering', link: '/railway-engineering' },
    { label: 'Sustainable Engineering', link: '/sustainable-engineering' },
  ];

  const mechanicalEngineeringItems = [
    { label: 'Thermal Engineering', link: '/thermal-engineering' },
    { label: 'Fluid Mechanics', link: '/fluid-mechanics' },
    { label: 'Mechanical Design', link: '/mechanical-design' },
    { label: 'Manufacturing Engineering', link: '/manufacturing-engineering' },
    { label: 'Robotics', link: '/robotics' },
    { label: 'Mechatronics', link: '/mechatronics' },
    { label: 'Materials Science and Engineering', link: '/materials-science' },
    { label: 'Dynamics and Control', link: '/dynamics-control' },
    { label: 'Aerospace Engineering', link: '/aerospace-engineering' },
    { label: 'Biomechanical Engineering', link: '/biomechanical-engineering' },
    { label: 'Nuclear Engineering', link: '/nuclear-engineering' },
    { label: 'Automotive Engineering', link: '/automotive-engineering' },
    { label: 'HVAC Engineering', link: '/hvac-engineering' },
    { label: 'Applied Mechanics', link: '/applied-mechanics' },
    { label: 'Vibration Engineering', link: '/vibration-engineering' },
    { label: 'Nanoengineering', link: '/nanoengineering' },
  ];
  const electricalEngineeringItems = [
    { label: 'Power Systems', link: '/power-systems' },
    { label: 'Control Systems', link: '/control-systems' },
    { label: 'Signal Processing', link: '/signal-processing' },
    { label: 'Electronics', link: '/electronics' },
    { label: 'Telecommunications', link: '/telecommunications' },
    { label: 'Microelectronics', link: '/microelectronics' },
    { label: 'Embedded Systems', link: '/embedded-systems' },
    { label: 'RF and Microwave Engineering', link: '/rf-microwave-engineering' },
    { label: 'Renewable Energy', link: '/renewable-energy' },
    { label: 'Electric Machines', link: '/electric-machines' },
  ];
  const computerEngineeringItems = [
    { label: 'Computer Hardware', link: '/computer-hardware' },
    { label: 'Software Engineering', link: '/software-engineering' },
    { label: 'Embedded Systems', link: '/embedded-systems' },
    { label: 'Network Engineering', link: '/network-engineering' },
    { label: 'Cybersecurity', link: '/cybersecurity' },
    { label: 'Artificial Intelligence', link: '/artificial-intelligence' },
    { label: 'Data Science', link: '/data-science' },
    { label: 'Human-Computer Interaction', link: '/hci' },
    { label: 'Database Management', link: '/database-management' },
  ];
  const chemicalEngineeringItems = [
    { label: 'Process Engineering', link: '/process-engineering' },
    { label: 'Biochemical Engineering', link: '/biochemical-engineering' },
    { label: 'Materials Engineering', link: '/materials-engineering' },
    { label: 'Environmental Engineering', link: '/environmental-engineering' },
    { label: 'Petroleum Engineering', link: '/petroleum-engineering' },
    { label: 'Pharmaceutical Engineering', link: '/pharmaceutical-engineering' },
    { label: 'Chemical Process Safety', link: '/chemical-process-safety' },
  ];
  const aerospaceEngineeringItems = [
    { label: 'Aerodynamics', link: '/aerodynamics' },
    { label: 'Propulsion', link: '/propulsion' },
    { label: 'Flight Mechanics', link: '/flight-mechanics' },
    { label: 'Avionics', link: '/avionics' },
    { label: 'Spacecraft Design', link: '/spacecraft-design' },
    { label: 'Structural Analysis', link: '/structural-analysis' },
  ];
  const industrialEngineeringItems = [
    { label: 'Operations Research', link: '/operations-research' },
    { label: 'Supply Chain Management', link: '/supply-chain-management' },
    { label: 'Quality Engineering', link: '/quality-engineering' },
    { label: 'Human Factors Engineering', link: '/human-factors-engineering' },
    { label: 'Manufacturing Systems', link: '/manufacturing-systems' },
  ];
  const environmentalEngineeringItems = [
    { label: 'Water Resources Management', link: '/water-resources-management' },
    { label: 'Air Quality Engineering', link: '/air-quality-engineering' },
    { label: 'Waste Management', link: '/waste-management' },
    { label: 'Sustainable Engineering', link: '/sustainable-engineering' },
  ];
            
  
  const tnpscSubjects = [
    { label: 'Group I Services', link: '/tnpsc/group-i' },
    { label: 'Group II Services', link: '/tnpsc/group-ii' },
    { label: 'Group III Services', link: '/tnpsc/group-iii' },
    { label: 'Group IV Services', link: '/tnpsc/group-iv' },
    { label: 'VAO (Village Administrative Officer)', link: '/tnpsc/vao' },
    { label: 'Combined Civil Services Exam', link: '/tnpsc/combined-civil-services' },
    { label: 'Civil Services Exam', link: '/tnpsc/civil-services' },
    { label: 'Assistant Public Prosecutor', link: '/tnpsc/assistant-public-prosecutor' },
    { label: 'Research Assistant', link: '/tnpsc/research-assistant' },
    { label: 'Junior Inspector', link: '/tnpsc/junior-inspector' },
    { label: 'Field Assistant', link: '/tnpsc/field-assistant' },
    { label: 'Forest Officer', link: '/tnpsc/forest-officer' },
    { label: 'Agricultural Officer', link: '/tnpsc/agricultural-officer' },
    { label: 'Statistical Inspector', link: '/tnpsc/statistical-inspector' },
    { label: 'Assistant Engineer', link: '/tnpsc/assistant-engineer' },
  ];
  const upscSubjects = [
    { label: 'Civil Services Preliminary Examination', link: '/upsc/civil-services-preliminary' },
    { label: 'Civil Services Main Examination', link: '/upsc/civil-services-main' },
    { label: 'Indian Forest Service Examination', link: '/upsc/forest-service' },
    { label: 'Engineering Services Examination', link: '/upsc/engineering-services' },
    { label: 'Geologists Examination', link: '/upsc/geologists' },
    { label: 'Combined Medical Services Examination', link: '/upsc/medical-services' },
    { label: 'Central Armed Police Forces Examination', link: '/upsc/capf' },
  ];
  

  const renderItemsAsColumns = (items) => {
    return items.map((item, index) => (
      <div className="column" key={index}>
        <div className="card classic-card">
          <h3 className="card-title">
            <FontAwesomeIcon icon={item.icon} className="category-icon" />
            {item.title}
          </h3>
          <div className="card-content">
            <ul>
              {item.data.map((dataItem, dataIndex) => (
                <li key={dataIndex}>
                  <FontAwesomeIcon icon={faAngleRight} className="arrow-icon" />
                  <a className="card-items" href={dataItem.link}>{dataItem.label}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ));
  };

 {/* const columnsData = [
    { title: 'Programming Languages', data: programmingLanguageItems },
    {title:'Civil Engineering',data:civilEngineeringItems},
    {title:'Mechanical Engineering',data:mechanicalEngineeringItems},
    {title:'Electrical Engineering',data:electricalEngineeringItems},
    {title:'Computer Engineering',data:computerEngineeringItems},

    {title:'Chemical Engineering',data:chemicalEngineeringItems},
    {title:'Aerospace Engineering',data:aerospaceEngineeringItems},
    {title:'Industrial Engineering',data:industrialEngineeringItems},
    {title:'Environmental Engineering',data:environmentalEngineeringItems},
   

    {title:'TNPSC',data:tnpscSubjects},
    {title:'UPSC',data:upscSubjects},

  ];*/}

  const columnsData = [
    { title: 'Programming Languages', data: programmingLanguageItems, icon: faCode },
    { title: 'Mechanical Engineering', data: mechanicalEngineeringItems, icon: faCogs },
    { title: 'Electrical Engineering', data: electricalEngineeringItems, icon: faBolt },
    { title: 'Computer Engineering', data: computerEngineeringItems, icon: faLaptopCode },
    { title: 'Aerospace Engineering', data: aerospaceEngineeringItems, icon: faSatellite },
    { title: 'Chemical Engineering', data: chemicalEngineeringItems, icon: faAtom },
    { title: 'Industrial Engineering', data: industrialEngineeringItems, icon: faIndustry },
    { title: 'Environmental Engineering', data: environmentalEngineeringItems, icon: faRecycle }
  ];
  const slideImages = [
    {
      url: 'mcq/home1.png',
    },
    {
      url: 'mcq/home2.png',
    }
  ];
 
  const divStyle = {
    margin:'auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '300px',
  };

  return (
    <div>
      <NavigationBar />
      <div className="slide-container">
        <Slide>
          <div className="slide" style={{ backgroundImage: `url(mcq/home1.png)` }}></div>
          <div className="slide" style={{ backgroundImage: `url(mcq/home2.png)` }}></div>
        </Slide>
      </div>
      <div className="columns">
        {columnsData.map((column, columnIndex) => (
          <React.Fragment key={columnIndex}>
            {renderItemsAsColumns([column])}
          </React.Fragment>
        ))}
      </div>
      <div className="home-description">
        <p>
          MCQ KIT is your ultimate destination for comprehensive multiple-choice questions (MCQs)...
        </p>
      </div>
      <Footer />

    </div>
  );
};

export default QuizCategory;
