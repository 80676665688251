import React, { useState } from 'react';
import './MCQPage.css';
import NavigationBar from '../Navbar/NavigationBar';

const Mainmcq = ({pageTitle,questionsData }) => {
  // State to store questions and their options
  const [questions] = useState(questionsData); // Set questions state with data from questionsData

  // State to track which question's answer to display
  const [currentQuestion, setCurrentQuestion] = useState(null);

  // Function to handle the "Show Answer" button click
  const showAnswer = (index) => {
    setCurrentQuestion(index);
  };

  return (
    <div>
      <div className="mcq-page">
        <h1>{pageTitle}</h1>
        <div className="question-container">
          {questions.map((q, index) => (
            <div key={index} className="question">
              <p>{index+1}.{q.question}</p>
              <ul className="options">
                {/* Render options A, B, C, and D */}
                {q.options.map((option, optionIndex) => (
                  <li key={optionIndex}>{String.fromCharCode(65 + optionIndex)}. {option}</li>
                ))}
              </ul>
              {currentQuestion === index && (
                <p className="answer">Answer: {q.answer}</p>
              )}
              <button onClick={() => showAnswer(index)} className='btm'>Show Answer</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mainmcq;
